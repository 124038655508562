import { useMemo, useState } from "react"
import useDataStore from "../helpers/dataStore"
import { Cartesian3, CatmullRomSpline, Ellipsoid, Math as CesiumMath, Color, HeightReference, EntityCollection, sampleTerrainMostDetailed } from "cesium"
import { CustomDataSource, CzmlDataSource, Entity, PolylineGraphics } from "resium"

const MapABMJourneyLines = () => {
    const myStore = useDataStore()
    const [selected_agent_terrainSampledPositions, set_selected_agent_terrainSampledPositions] = useState(null)
    const [simLocationLines, set_simLocationLines] = useState(null)
    // const get_arc = (origin, destination, id) => {
    //     console.log("GET ARC FROM ", origin)
    //     console.log("TO", destination)


    //     const origin_c3 = Cartesian3.fromDegrees(origin.longitude, origin.latitude)
    //     const destination_c3   = Cartesian3.fromDegrees  (destination.longitude,   destination.latitude)
    //     const distance = Cartesian3.distance(origin_c3, destination_c3)

    //     const cartesian3_points = Cartesian3.fromDegreesArrayHeights([
    //         origin.longitude, origin.latitude, 0, 
    //         (origin.longitude + destination.longitude)/2, (origin.latitude  + destination.latitude)/2, 200+Math.sqrt(distance),
    //         destination.longitude, destination.latitude, 0
    //     ])


    //     const spline = new CatmullRomSpline({
    //         times: [0.0, 0.5, 1.0], 
    //         points: cartesian3_points
    //     })

    //     const points = []
    //     for (let j = 0.0; j < 1.0; j+= 0.05) {
    //         const cartographic = spline.evaluate(j)

    //         const carto = Ellipsoid.WGS84.cartesianToCartographic(cartographic)
    //         var lon = CesiumMath.toDegrees(carto.longitude)
    //         var lat = CesiumMath.toDegrees(carto.latitude)

    //         points.push(Cartesian3.fromDegrees(lon, lat, carto.height))
    //     }

    //     return points
    // }

    
    // draw arcs between points



    const get_arc = (origin, destination, id) => {
        // console.log("GET ARC FROM ", origin);
        // console.log("TO", destination);
    
        const positions = [
            origin,
            destination
        ]

        const originCartographic = Cartesian3.fromDegrees(origin.longitude, origin.latitude);
        const destinationCartographic = Cartesian3.fromDegrees(destination.longitude, destination.latitude);
                
        const distance = Cartesian3.distance(originCartographic, destinationCartographic);
        // console.log(`distance: ${distance}`)
    
        const degreesAndHeights = [
            origin.longitude, origin.latitude, origin.height, 
            (origin.longitude + destination.longitude) / 2, 
            (origin.latitude + destination.latitude) / 2, 
            .5*(destination.height + destination.height) + 200 + Math.sqrt(distance),
            destination.longitude, destination.latitude, destination.height
        ]

        // console.log('deg + heights', degreesAndHeights)
        const cartesian3_points = Cartesian3.fromDegreesArrayHeights(degreesAndHeights);
    
        // console.log('c3 points', cartesian3_points)

        const spline = new CatmullRomSpline({
            times: [0.0, 0.5, 1.0], 
            points: cartesian3_points
        });
    
        const points = [];
        for (let j = 0.0; j < 1.0; j += 0.05) {
            const cartographic = spline.evaluate(j);
    
            const carto = Ellipsoid.WGS84.cartesianToCartographic(cartographic);
            const lon = CesiumMath.toDegrees(carto.longitude);
            const lat = CesiumMath.toDegrees(carto.latitude);
            points.push(Cartesian3.fromDegrees(lon, lat, carto.height));
        }
    
        // console.log("returning points", points)
        return points;
    };


    // set set_simLocationLines
    useMemo(() => {
        // console.log('SimLocationLines', selected_agent_terrainSampledPositions)


        if (! selected_agent_terrainSampledPositions) {
            // console.warn('selected_agent_terrainSampledPositions is null')
            set_simLocationLines(null)
            return
        }

        const agent = myStore?.abm_selected?.agent
        

        if (! agent) {
            // console.warn('agent is null')
            set_simLocationLines(null)
            return
        }

        
        // console.log("AGENT", agent)

        const arcs = []

        var previous_location = null

        
        agent.positions.map((position, px) => {
            if (! position.location) {
                console.warn(`${px} no location`, position)
                return
            }

            // const location = myStore.abm_diaries.locations[position.location]
            const locationWithTerrain = selected_agent_terrainSampledPositions[position.location]

            if (!locationWithTerrain) {
                console.warn(`${px} location '${position.location}' not found`)
                return
            }

            if (locationWithTerrain == previous_location) {
                // console.log(`${px} location = previous_location`)
                return
            }

            if (previous_location == null) {
                previous_location = locationWithTerrain
                // console.log(`${px} previous location is null, no journey to add`, locationWithTerrain)
                return
            }

            // previous location is different, so add a journey!

            // console.log(`${px} adding journey to`, locationWithTerrain)

            arcs.push({
                id: `agent_arc_${px}`,
                positions: get_arc(previous_location, locationWithTerrain, `agent_arc_${px}`)
            })

            previous_location = locationWithTerrain
        })


        // console.log("ARCS", arcs)

        const polylines = arcs.map((arc,ax) => {

            // console.log(`arc ${ax}`, arc)

            return <Entity 
                    key={arc.id}
                    heightReference={HeightReference.RELATIVE_TO_GROUND}
                    >
                <PolylineGraphics
                    positions={arc.positions}
                    width={12}
                    material={Color.fromRandom({red:1, alpha:1})}
                    // heightReference={HeightReference.NONE}
                    // clampToGround={true}
                />
            </Entity>
        })  

        // console.log('polyline arcs', polylines)


        // eslint-disable-next-line react/display-name
        set_simLocationLines( polylines )
    },[selected_agent_terrainSampledPositions])

    // when agent is selected, calc 3d points the agent has been to:
    useMemo(async () => {
        const agent = myStore?.abm_selected?.agent


        // console.log("selected_agent:", agent)

        if (!  agent) {
            set_selected_agent_terrainSampledPositions(null)
            return null
        }

        const positions = {}
        var previous_location = null


        agent.positions.map((position, px) => {
            if (! position.location) {
                return
            }

            // is location already calc'd?
            if (positions[position.location]) {
                return
            }

            const location = myStore.abm_diaries.locations[position.location]

            if (!location) {
                console.warn(`${px} location '${position.location}' not found`)
                return
            }

            // previous location is different, so add a journey!

            // console.log(`${px} getting heights for ${position.location}`, location)

            // positions[position.location] = Ellipsoid.WGS84.cartesianToCartographic(
            //             Cartesian3.fromDegrees(location.longitude, location.latitude)
            //     )
            // positions[position.location] = Cartesian3.fromDegrees(location.longitude, location.latitude)

            const carto = Ellipsoid.WGS84.cartesianToCartographic(Cartesian3.fromDegrees(location.longitude, location.latitude))


            const newPositionLatLngHeight = {
                longitude: CesiumMath.toDegrees(carto.longitude),
                latitude:  CesiumMath.toDegrees(carto.latitude),
                height: carto.height
            }

            positions[position.location] = newPositionLatLngHeight
        })

        // Sample terrain heights at origin and destination
        const terrainSampledPositions = await sampleTerrainMostDetailed(myStore.viewerCesiumElement.terrainProvider, positions);

        set_selected_agent_terrainSampledPositions(terrainSampledPositions)
        
    }, [ myStore?.abm_selected?.agent])
    

    const SimLocationLinesElement = useMemo(() => {
        return () => simLocationLines

    }, [simLocationLines])

    return <CustomDataSource>
        <SimLocationLinesElement />
    </CustomDataSource>
}

export default MapABMJourneyLines