import React, { useMemo, useRef, useState } from "react"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import useDataStore from "../helpers/dataStore"
import kiwiana from "../assets/ai_art/kiwiana.jpg"
import tonga_water from "../assets/ai_art/tonga_water.jpg"
import auckland_about from "../assets/ai_art/auckland_about.jpg"
import config from "../helpers/config"
import About from "./About"

const ModalIntro = () => {
    const myStore = useDataStore()
    const refSecretSauce = useRef()
    const [loggedIn, setLoggedIn] = useState(true)
    const [showAbout, setShowAbout] = useState(false)

    const handleClose = () => {
        console.log("closing intro")
        if (loggedIn) {
            myStore.fnSetKeyValue('status_show_modal_intro', false)
            myStore.fnSetKeyValue('status_show_modal_data', true)
        } else {
            console.warn("not logged in.")
        }
    }

    const login = () => {
        console.log("LOGIN?", refSecretSauce.current.value)

        if (refSecretSauce.current.value == myStore.not_very_secure_secret) {
            setLoggedIn(true)
            handleClose()
        }
    }

    const selectTwin = (id) => {
        const selectedTwin = config.twins[id]
        myStore.fnSetKeyValue('selected_twin', selectedTwin)
        handleClose()
    }

    const LoginElement = useMemo(() => {

        console.info("login element*** ", loggedIn)

        if (loggedIn) {
            return () => <Container>
                <Row>
                    <Col>
                        <Button onClick={handleClose}>Close</Button>
                    </Col>
                </Row>
                </Container>
        }

        return () => <Form>
                <Container>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="password" placeholder="Password" ref={refSecretSauce} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant='secondary' onClick={login} type='submit'>Login</Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        
    },[loggedIn])

    return <Modal 
            show={myStore.status_show_modal_intro} 
            onHide={handleClose} 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
                fontSize:".8em"
            }}
            {...(loggedIn ? null : {'backdrop': 'static'})}
        >
            <Modal.Header
                className='introRainbow'
                style={{
                        width:'100%',
                        backgroundColor: '#888888',
                    }}>
                <Modal.Title
                    style={{
                            width:'100%',
                        }}                        
                    >
                    <b style={{color:'#171717'}}>ALMA</b>
                    {' '}
                    <span style={{color:'#eeeeee'}}>
                        - Aotearoa's Large-scale Multi-Agent Simulator
                    </span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor: '#eeeeee'}}>
                <Container>
                    <Row style={{color:'#434343'}}>
                        <Col>
                            <p>We’re developing AI digital twins for future modeling. <a href='mailto:datascience@esr.cri.nz?subject=Digital twins'>Contact us</a> for more info, demo requests, or to share your ideas.</p>
                        </Col>
                    </Row>
                    {
                        showAbout
                        &&
                        <Row>
                            <Col>
                                <About />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            <Button 
                            variant='danger' 
                            style={{ height: '250px', padding: 'auto 20px', verticalAlign:'middle', width:'100%'}}
                            onClick={()=> {
                                setShowAbout(! showAbout)
                            }}
                            >
                                <img src={auckland_about} style={{width:'100%'}}/>    
                                About
                            </Button>
                        </Col>
                        <Col>
                            <Button variant='success' style={{ 
                                        height: '250px', 
                                        padding: 'auto 20px', 
                                        verticalAlign:'middle', 
                                        width:'100%',                                
                                    }}
                                    onClick={() => selectTwin('nz')}
                                >
                                    <img src={kiwiana} style={{width:'100%'}}/>    
                                    New Zealand
                                </Button>
                        </Col>
                        <Col>
                            <Button variant='warning' 
                                    style={{ height: '250px', padding: 'auto 20px', verticalAlign:'middle', width:'100%'}}
                                    onClick={() => selectTwin('tonga')}
                                >
                                <img src={tonga_water} style={{width:'100%'}}/>    
                                Tonga
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <LoginElement />
            </Modal.Footer>
        </Modal>

}

export default ModalIntro