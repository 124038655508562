import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'

import 'bootstrap/dist/css/bootstrap.min.css';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Router from './Router.jsx';



ReactDOM.createRoot(document.getElementById('root')).render(
    // <RouterProvider router={router} />
    <Router />
)
