import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
import Disclaimer from "./Disclaimer";
import Login from "./Login";
import ProtectedApp from "./ProtectedApp";
import App from "./App";
import Licence from "./Licence";
import LazyComponent from "./components/LazyComponent";

const Router = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <ProtectedApp>
                        <App />
                    </ProtectedApp>,
            errorElement: <ErrorPage />,
        },
        {
            path: "/disclaimer",
            element: <Disclaimer />
        },
        {
            path: "/licence",
            element: <Licence />
        },
        {
            path: "/terms",
            // element: <TermsOfUse />
            element: <LazyComponent component='TermsOfUse' key='TermsOfUseKey' />
        },
        {
            path: "/login",
            element: <LazyComponent component='Login' key='LoginKey' />
        }
      ]);

    return <RouterProvider router={router} />
}

export default Router;