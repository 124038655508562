import { useMemo } from "react"
import useDataStore from "../helpers/dataStore"
import { Entity, GeoJsonDataSource } from "resium"
import { Color } from "cesium"
import getBoundingBox from "../helpers/getBoundingBox"


const MapCustomUserLayers = () => {
    const myStore = useDataStore()

    



    return useMemo(() => {
        // console.log('MapCustomUserLayers called')

        const keys = Object.keys(myStore.map_custom_layers)

        return keys.map((key, kx) => {
            const layer_data = myStore.map_custom_layers[key]
            // console.log(`LAYER ${key}`, layer_data)
            var shp = layer_data?.shp
            console.log("DATA FOR GEOJSON:", shp)

            // console.log("BB", shp?.bbox)

            const getStyle = (properties) => {
                // console.log("Getting style for ", properties)

                // start with default style
                const style = {
                    color: Color.RED,
                    width: 2
                }
        
                if (! layer_data?.style) {
                    return style
                }

                // initially, update using  defaults in layer_data
                if (layer_data.style?.color) {
                    style.color = Color.fromCssColorString(layer_data.style.default.style.color)
                }
                if (layer_data.style?.default.width) {
                    style.width = layer_data.style?.default.width
                }

                if (layer_data.style?.fields) {
                    Object.entries(layer_data.style?.fields).map(([field,value_configs],sx) => {
                        const field_value = properties?.[field].getValue()

                        const style_for_field = value_configs?.[field_value]

                        // eg = { color: '#ddfffa', width: '30' }
                        if (style_for_field) {
                            Object.entries(style_for_field).map(([styleKey, styleValue], ex) => {
                                if (styleKey == 'color') {
                                    style[styleKey] = Color.fromCssColorString(styleValue)
                                } else {
                                    style[styleKey] = styleValue
                                }
                            })
                        }                        
                    })
                }

                return style
            };







            return <Entity clampToGround={true} key={`map_custom_layer_${kx}`}>                
                <GeoJsonDataSource 
                    data={shp?.geojson} 
                    key={`gjs_${kx}`}
                    // onLoad={() => { console.log(`LOADED ${kx}`) }}
                    onLoad={(geoJsonDataSource) => {
                        const entities = geoJsonDataSource.entities.values;
                        entities.forEach(entity => {
                            const properties = entity.properties;
                            const style = getStyle(properties);
                            if (style?.show == false) {
                                entity.show = false; // HIDE
                            }
                            else
                            {   
                                entity.polyline.material = style.color;
                                entity.polyline.width = style.width;
                            }
                        });
                        // console.log(`LOADED ${key}`);
                    }}
                    stroke={Color.RED}                    
                    markerColor={Color.ORANGE}
                    markerSymbol={"X"}
                    fill={Color.AQUA.withAlpha(0.3)}
                    clampToGround={true}
                />
            </Entity>
        })

    }, [myStore.map_custom_layers])
}

export default MapCustomUserLayers