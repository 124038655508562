import { useMemo } from "react"
import useDataStore from "../helpers/dataStore"
import { Button, Col, Container, Row } from "react-bootstrap"
import Chart from "react-apexcharts";
import "./TongaCharts.css"

function WellData() {

    const myStore = useDataStore()

    const element = useMemo(() => {
        if (! myStore.selected_well) {
            return null
        }

        const properties = myStore.selected_well.data.well.features[0].properties

        // console.info('properties', properties)

        const ph_chart_options = {
            chart: {
                id: 'ph_chart',
                toolbar: {
                    show: false
                },
                // parentHeightOffset: 0 // This can help reduce extra space
            },
            grid: {
                row: {
                  colors: ['#43434355', '#43434344', '#43434333']
                },
                column: {
                  colors: ['#F44336', '#E91E63', '#9C27B0']
                },
              },
            xaxis: {
                categories: myStore.selected_well.data.data.map((reading, index) => {
                        return reading.date
                    }),
                type: 'datetime',
                labels: {
                    format: 'MMM \'yy',
                    style: {
                        colors: '#434343',
                        fontSize: '16px',
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#434343",
                        fontSize: '16px',
                    }
                }
            },
            stroke: {
                // curve: 'stepline'
                curve: 'smooth',
            },
            markers: {
                size: 10,
            },
            colors: ['#FF0000'],
            tooltip: {
                theme: 'dark',
                x: {
                    format: "dd MMM yyyy",
                },
            },
        }

        const ph_chart_series = [
            {
                name: 'pH - line',
                type: 'line',
                data: myStore.selected_well.data.data.map((reading, index) => {
                    return (reading.pH != 0 ? reading.pH : null)
                }),
            },
        ]




        
        const temperature_chart_options = {
            chart: {
                id: 'temperature_chart',
                toolbar: {
                    show: false
                }
            },
            grid: {
                row: {
                  colors: ['#43434355', '#43434344', '#43434333']
                },
                // column: {
                //   colors: ['#F44336', '#E91E63', '#9C27B0']
                // }
              },
            legend: {
                labels: {
                    colors: "#434343"
                }
            },
            xaxis: {
                categories: myStore.selected_well.data.data.map((reading, index) => {
                        return reading.date
                    }),
                type: 'datetime',
                labels: {
                    format: 'MMM \'yy',
                    style: {
                        colors: '#434343',
                        // fill: "#FF00FF",
                        fontSize: '16px',
                        // cssClass: 'grr-x'
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#434343",
                        fontSize: '16px',
                    }
                }
            },
            stroke: {
                // curve: 'stepline'
                curve: 'smooth',
            },
            markers: {
                size: 10,
            },
            colors: ['#FFFF00CC','#3333FFCC'],
            tooltip: {
                theme: 'dark',
                x: {
                    format: "dd MMM yyyy",
                }
            }
        }
        
        const temerature_chart_series = [
            {
                name: 'Base °c',
                type: 'line',
                data: myStore.selected_well.data.data.map((reading, index) => {
                    // return reading['Temperature at base']
                    return (reading['Temperatur'] != 0? reading['Temperatur'] : null)
                }),
                color: "#1D7DF7CC", // Primary 60
            },
            {
                name: 'Solonist °c',
                type: 'line',
                data: myStore.selected_well.data.data.map((reading, index) => {
                    // return reading['TLC Solonist Temperature']
                    return (reading['TLC_Solo_1'] != 0? reading['TLC_Solo_1'] : null)
                }),
                color: "#9F33DECC" //Kumara 70
            },
        ]



        
        
        const conductivity_chart_options = {
            chart: {
                id: 'conductivity_chart',
                toolbar: {
                    show: false
                }
            },
            grid: {
                row: {
                  colors: ['#43434322', '#43434333', '#43434344']
                },
                // column: {
                //   colors: ['#F44336', '#E91E63', '#9C27B0']
                // }
              },
            legend: {
                labels: {
                    colors: "#434343"
                }
            },
            xaxis: {
                categories: myStore.selected_well.data.data.map((reading, index) => {
                        return reading.date
                    }),
                type: 'datetime',
                labels: {
                    format: 'MMM \'yy',
                    style: {
                        colors: '#434343',
                        // fill: "#FF00FF",
                        fontSize: '16px',
                        // cssClass: 'grr-x'
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: function(value) { 
                        return value.toLocaleString() 
                    },
                    style: {
                        colors: "#434343",
                        fontSize: '16px',
                    }
                }
            },
            stroke: {
                // curve: 'stepline'
                curve: 'smooth',
            },
            markers: {
                size: 10,
            },
            // colors: ['#FFFF00CC','#3333FFCC'],
            tooltip: {
                theme: 'dark',
                x: {
                    format: "dd MMM yyyy",
                }
            }
        }

        
        const conductivity_chart_series = [
            {
                name: 'Conductivity',
                type: 'line',
                data: myStore.selected_well.data.data.map((reading, index) => {
                    // return reading['Temperature at base']
                    return (reading['EC_at_base'] != 0? reading['EC_at_base'] : null)
                }),
                color: "#95BF3A", // ESR Grass
            },
        ]


        const chart_ph = <Chart 
                options={ph_chart_options}
                series={ph_chart_series}
                height='180px'
            />

        
        const chart_temperature = <Chart 
            options={temperature_chart_options}
            series={temerature_chart_series}
            // width='670px'
            height='190px'
        />

        
        const chart_conductivity = <Chart 
            options={conductivity_chart_options}
            series={conductivity_chart_series}
            // width='670px'
            height='180px'
        />


        return <div style={{
            marginTop: '1em'
        }}>
            <Container>
                <Row>
                    <Col md={9}>
                        <div><strong>{properties['bhi_name']}</strong></div>
                        <div>{properties['ADM1_NAME']} &gt; {properties['ADM2_NAME']} &gt; {properties['ADM3_NAME']}</div>
                    </Col>
                    <Col>
                        <Button variant='secondary' onClick={() => {
                            console.log("close well")
                            myStore.fnSetKeyValue('selected_well', null)
                        }}>Close</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 style={{
                            margin: '10px 0 0',
                            fontSize: '1.2em'
                        }}>pH</h3>
                    </Col>
                </Row>
                <Row className="no-gutters">
                    <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        { chart_ph }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 style={{
                            margin: '10px 0 0',
                            fontSize: '1.2em'
                        }}>Temperature</h3>
                    </Col>
                </Row>
                <Row className="no-gutters">
                    <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        { chart_temperature }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 style={{
                            // color: '#99CCFF',
                            margin: '10px 0 0',
                            fontSize: '1.2em'
                        }}>Conductivity</h3>
                    </Col>
                </Row>
                <Row className="no-gutters">
                    <Col style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        { chart_conductivity }
                    </Col>
                </Row>
            </Container>
            
            
            {/* { chart_ph }
            <h3 style={{
                color: '#FFCC99',
                margin: '15px 0 0',
                fontSize: '1.3em'
            }}>Chart - Temperature</h3> */}
            {/* { chart_temperature } */}
            {/* <h3 style={{
                color: '#99FFCC',
                margin: '15px 0 0',
                fontSize: '1.3em'
            }}>Chart - Palintest_</h3>
            { chart_ph } */}
            {/* <div
                style={{
                    overflowX: 'auto'
                }}
            >
                <table>{ dataTable }</table>
            </div> */}
        </div>

    }, [myStore?.selected_well])

    return element
}

export default WellData