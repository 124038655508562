// Function to calculate the bounding box
function getBoundingBox(geojson) {
    console.log("GBB", geojson)
    let minLat = Number.POSITIVE_INFINITY;
    let maxLat = Number.NEGATIVE_INFINITY;
    let minLng = Number.POSITIVE_INFINITY;
    let maxLng = Number.NEGATIVE_INFINITY;

    const recursiveCheckCoords = (coord) => {
        if (typeof coord[0] == "object") {
            return coord.map((v,ix) => recursiveCheckCoords(v))
        }

        // okay, array[0] isnt an object so compare
        const [lng, lat] = coord;
        if (lat < minLat) minLat = lat;
        if (lat > maxLat) maxLat = lat;
        if (lng < minLng) minLng = lng;
        if (lng > maxLng) maxLng = lng;
    }
  
    if (geojson?.features) {
        geojson.features.forEach(feature => {
            const coordinates = feature.geometry.coordinates;
            recursiveCheckCoords(coordinates)
        })
    }
    else if (geojson?.coordinates) {
        recursiveCheckCoords(geojson.coordinates)
    }

    const result = { 
        minLat: minLat, 
        maxLat: maxLat, 
        minLng: minLng, 
        maxLng: maxLng 
    };

    console.log('resulting bounds', result)

    return result
}


export default getBoundingBox