import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDataStore from "./helpers/dataStore";
import axios from "axios";
import config from "./helpers/config";
import { Spinner } from "react-bootstrap";

const ProtectedApp = ({children}) => {
    const myStore = useDataStore();
    const accepted_disclaimer = localStorage.getItem('acceptedDisclaimer') === 'true'
    const user_token = localStorage.getItem('dt_token')

    const [ tokenStatus, setTokenStatus ] = useState('unchecked') // busy / bad / unchecked / good.
    
    const navigate = useNavigate()

    useEffect(() => {
        // console.log(`token status=${tokenStatus}`)

        if (tokenStatus == 'good') {
            return
        }

        if (tokenStatus == 'busy') {
            return
        }

        if (tokenStatus == 'bad') {
            console.log('token bad, navigating to login')
            navigate('/login');
            return
        }

        if (!accepted_disclaimer) {
            console.log('need to accept disclaimer')
            navigate('/disclaimer');
            return;
        }

        console.log(`token status=${tokenStatus}.. checking token.`)

        const api_axios_header = {
            headers: {
                Authorization: `Bearer ${user_token}`
            }
        };

        const verifyToken = async () => {
            try {
                const response = await axios.get(`${config.dev_api_url}/user/checkToken`, api_axios_header);
                myStore.fnCallback((state) => {
                    console.log(`response.data.user=`, response.data.user)
                    state.token = user_token;
                    state.dt_user = JSON.parse(localStorage.getItem('dt_user'));
                    state.api_axios_header = api_axios_header;
                });
                setTokenStatus('good');
            } catch (err) {
                console.warn("ERROR", err);
                setTokenStatus('bad')
                navigate('/login');
            }
        };

        verifyToken();
    }, [tokenStatus]);

    const element = useMemo(() => {
        if (!tokenStatus == 'busy') {
            return <div>Please wait... verifying.</div>;
        }
    
        
        if (tokenStatus == 'good') {
            return children
        }

        if (tokenStatus == 'unchecked') {
            return <div><Spinner /> Please wait.. checking token.</div>
        }    
    
        return <div>Token=${tokenStatus}</div>
    }, [tokenStatus])
    
    return element
    
}

export default ProtectedApp;