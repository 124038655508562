/* eslint-disable react/display-name */
import React, { useMemo, useState } from "react"
import { Alert, AlertHeading, Button, Col, Container, Modal, Row } from "react-bootstrap"
import useDataStore from "../helpers/dataStore"
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { current } from "immer"
import map_point_in_polygon from "../helpers/map_point_in_polygon"
import MapABM from "./MapABM"
import LeftMenuABM from "./LeftMenuABM"
import config from "../helpers/config"
import axios from "axios"
import MapGeoJSON from "./MapGeoJSON"
import MapABMRouting from "./MapABMRouting"
import LeftMenuABMRouting from "./LeftMenuABMRouting"
import { CheckCircle } from "react-bootstrap-icons"
import loadCandidateCallback from "../helpers/loadCandidateCallback.jsx"
import LazyComponent from "./LazyComponent.jsx"

const ModalData = () => {
    const myStore = useDataStore()
    const [candidateDataSet, setCandidateDataSet] = useState(null)
    const [dataSetFooterMessage, setDatasetFooterMessage] = useState(null)

    // useMemo(() => {
    //     console.log("Setting show = true")

    //     myStore.fnSetKeyValue('status_show_modal_data', true)
    // }, [])

    const handleClose = () => {
        console.log("closing modal")
        myStore.fnSetKeyValue('status_show_modal_data', false)
    }

    const allDataCollections = [
        {
            id: 'tonga_admin',
            title: 'Administrative boundaries in Tonga',
            twins: ['tonga']
        },
        {
            id: 'nz_admin',
            title: "Administrative boundaries in New Zealand",
            twins: ['nz']
        },
        // {
        //     id: 'nz_health',
        //     title: "Health New Zealand",
        //     twins: ['nz']
        // },
        {
            id: 'nz_infrastructure',
            title: "New Zealand Infrastructure",
            twins: ['nz']
        },
        {
            id: 'nz_modelling',
            title: "New Zealand Modelling",
            twins: ['nz']
        }
    ]
     
    const allDataSets = [
        // -- Tonga admin ---
        
        {
            title: "Islands",
            description: "Tonga islands",
            dataCollections: ['tonga_admin'],
            source: "Government of Tonga",
            // sourceURL: "https://www.lawa.org.nz/download-data",
            // dateRange: "Realtime",
            twins: ['tonga'],
            config: {
                map_layer: <MapGeoJSON source_key='tonga_islands' />,
            // left_menu_data_source: <LeftMenuTonga />,
            },
        },
        {
            title: "Districts",
            description: "Tonga districts",
            dataCollections: ['tonga_admin'],
            source: "Government of Tonga",
            // sourceURL: "https://www.lawa.org.nz/download-data",
            // dateRange: "Realtime",
            twins: ['tonga'],
            config: {
                map_layer: <MapGeoJSON source_key='tonga_districts' />,
            // left_menu_data_source: <LeftMenuTonga />,
            },
        },

        {
            title: "Villages",
            description: "Tonga villages",
            dataCollections: ['tonga_admin'],
            source: "Government of Tonga",
            // sourceURL: "https://www.lawa.org.nz/download-data",
            // dateRange: "Realtime",
            twins: ['tonga'],
            config: {
                map_layer: <MapGeoJSON source_key='tonga_villages' />,
            // left_menu_data_source: <LeftMenuTonga />,
            },
        },


        // -- NZ health data here --
        // {
        //     title: "Covid cases by SA2",
        //     description: "Weekly covid case numbers by SA2 during 2022-23",
        //     dataCollections: ["nz_health"],
        //     dateRange: "2022-23",
        //     twins: ['nz'],
        // },
        // {
        //     title: "Covid in wastewater test results",
        //     description: "Weekly results from covid in wastewater testing data across New Zealand",
        //     dataCollections: ['nz_health'],
        //     dateRange: "2022 onwards",
        //     twins: ['nz'],
        // },

        // -- NZ admin layers
        {
            title: "Statistical Area 2 - Population Projections",
            thumbnail: {filename: "/sa2_projections_2048.jpg", width: '300', height: '300' },
            description: `
**Population projections to 2048 from StatsNZ**

Three scenarios are modelled - a low, medium and high population growth scenario.

The visualisation allows user to select a projection and see population sizes on the map as height columns.

Clicking on a SA2's height column will show a population pyramid for that SA2 and the selected time period.

[Data provided by Stats.NZ](https://www.stats.govt.nz/information-releases/statistical-area-2-population-projections-2018base2048-update-nz-stat-tables/)
`,
            dataCollections: ['nz_admin'],
            dateRange: ['2015','2018','2020','2022'],
            twins: ['nz'],
            config: {
                map_layer: [
                    <LazyComponent component="MapSA2PopulationEstimates" />
                ],
                left_menu_data_source: <LazyComponent component="LeftMenuMapSA2PopulationEstimates" />
            }
        },
//         {
//             title: "Statistical Area 3",
//             description: `Statistical area 3 (SA3) is a new output geography, introduced in 2023, that allows aggregations of population data between the SA2 geography and territorial authority geography.

// This dataset is the definitive version of the annually released statistical area 3 (SA3) boundaries as at 1 January 2023 as defined by Stats NZ. This version contains 929 SA3s, including 4 non-digitised SA3s.

// 1. The SA3 geography aims to meet three purposes:

// 2. approximate suburbs in major, large, and medium urban areas,

// 3. in predominantly rural areas, provide geographical areas that are larger in area and population size than SA2s but smaller than territorial authorities,

// minimise data suppression.`,
//             dataCollections: ['nz_admin'],
//             dateRange: ['2023'],
//             twins: ['nz'],
//         },

        // -- nz infrastructure here --
        
        {
            title: "Metlink realtime",
            description: "Live tracking of busses, ferries and trains around Wellington from the Metlink API",
            dataCollections: ['nz_infrastructure'],
            dateRange: "Realtime",
            twins: ['nz'],
            source: "metlink",
            thumbnail: {filename: "/metlink.jpg", width: '300', height: '300' },
            config: {
                map_layer: [
                    // <MapMetlink key='metlink' />
                    <LazyComponent component="MapMetlink" />
                ]
            }
        },
        // {
        //     title: "LAWA recreational water quality",
        //     description: "This data file contains faecal indicator bacterial (FIB) monitoring results from council recreational water quality monitoring programmes over the last six years (2017/18 - 2022/23).  For some sites, potentially toxic algae information is also available. ",
        //     dataCollections: ['nz_infrastructure'],
        //     dateRange: "2017-2023",
        //     twins: ['nz'],
        //     source: "LAWA",
        //     sourceURL: "https://www.lawa.org.nz/download-data",
        // },
        // {
        //     title: "LAWA Air quality dataset (2016-2023)",
        //     description: "This data file contains daily average PM2.5 and PM10 data from air quality monitoring sites over the last eight years (2016-2023), and 10-year trend results.  Data file published: 9 July 2024.",
        //     dataCollections: ['nz_infrastructure'],
        //     dateRange: "2016-2023",
        //     twins: ['nz'],
        //     source: "LAWA",
        //     sourceURL: "https://www.lawa.org.nz/download-data",
        // },
        // {
        //     title: "LAWA Groundwater quality dataset (2004-2022)",
        //     description: "This data file contains the monitoring results for groundwater quality samples (2004 - 2022), and state and trend results for well sites throughout New Zealand.  Data file published: 20 November 2023.",
        //     dataCollections: ['nz_infrastructure'],
        //     dateRange: "Realtime",
        //     twins: ['nz'],
        //     source: "LAWA",
        //     sourceURL: "https://www.lawa.org.nz/download-data",
        // },
        // {
        //     title: "Lake water quality",
        //     description: "This data file contains the monitoring results of lake water quality samples (2004-2022), and results from calculations for TLI, state and trends in Sept 2023.  Data file published: 16 October 2023.",
        //     source: "LAWA",
        //     sourceURL: "https://www.lawa.org.nz/download-data",
        //     dataCollections: ['nz_infrastructure'],
        //     dateRange: "Realtime",
        //     twins: ['nz'],
        // },
        // {
        //     title: "River water quality",
        //     description: "Various datasets, including.. River macroinvertebrate monitoring results (2004-2022)",
        //     dataCollections: ['nz_infrastructure'],
        //     source: "LAWA",
        //     sourceURL: "https://www.lawa.org.nz/download-data",
        //     dateRange: "Realtime",
        //     twins: ['nz'],
        // },


        {
            id: 'abm_x_wellington_20240913',
            title: "Agent Based Modelling - Explorer - Wellington",
            description: `This layer allows users to explore the locations, agents and diaries used in the underlying New Zealand - Wellington agent based modelling data set.
            
There are a number of ways to interact with the data:

- Users can click on an area of the map to select a suburb (sa2), which will display locations within that suburb, such as supermarkets, restaurants and businesses.
- Users can zoom in to a small area then click 'load agents' to display a selection of agents who reside or travel through the map bounds according to their synthetic diary. 
    - Use the timeline to animate the diary over 24 hours.
    - Click on an agent on the map to view the agent diary.
`,
            dataCollections: ['nz_modelling'],
            twins: ['nz'],
            thumbnail: {filename: "/abm_explorer.jpg", width: '300', height: '300' },
            config: {
                datasetsToUnload: ['abm_x_auckland_20241209'],
                map_layer: <MapABM model='wellington_20240913'  key='mapABM' />,
                left_menu_data_source: <LeftMenuABM model_id='wellington_20240913' key='LeftMenuABM' />,
                map_event_callbacks: [
                    {
                        event_type: "LEFT_CLICK",
                        callback: (map_event, currentStore) => {
                            console.log(`ABM map event ${myStore.selected_areas.sa2.length} .. LAT:${map_event.latitude} LNG:${map_event.longitude}`, map_event)

                            if (currentStore.selected_areas['sa2'].length > 0) {
                                // console.log("PREVENTING ***** propogation as sa2 is selected.", currentStore.selected_areas)
                                return
                            } 
                            console.log("NO SA2 selected, so searcing for one:", myStore.selected_areas)
                            
                            currentStore.fnCallback((state) => {
                                state.working_sa2_point_in_polygon = "Searching for SA2..."
                            })
                            

                            // get SA2 at these coordinates
                            map_point_in_polygon(
                                'sa2_2022',
                                map_event.latitude,
                                map_event.longitude,
                                (geo_result) => {
                                    console.warn('callback goes here', geo_result)
                                    currentStore.fnCallback((state) => { 
                                        state.abm_locations = [] 
                                        if (geo_result?.area) {
                                            console.log(`Clicked in ${geo_result.area.area_name} (${geo_result.area.area_code})`)
                                            state.selected_areas['sa2'] = [geo_result.area]
                                        } else {
                                            console.log("no area")
                                            state.selected_areas['sa2'] = [] 
                                        }
                                        state.working_sa2_point_in_polygon = (geo_result?.area ? "Loading locations..." : false)
                                    })

                                    // finally - load sa2 location info from API
                                    if (geo_result?.area) {
                                        console.log(`loading locations for ${geo_result.area.area_code}`)
                                        
                                        const url = `${config.dev_api_url}/get_sa2_locations/wellington_20240913/${geo_result.area.area_code}`
                                        console.log('loading locations from ', url)

                                        axios
                                        .get(url, myStore.api_axios_header)
                                        .then((response) => {
                                            console.log("recceived ", response.data)

                                            // parse locations and replace nulls here:

                                            var locations = response.data.locations

                                            // handle locations with null for location_name
                                            locations = locations.map((location, ix) => {
                                                location = Object.assign({}, location) // locations starts off as read only
                                                location.location_name = (location?.location_name ? location.location_name : location.location_id)
                                                return location
                                            })
                                            currentStore.fnCallback((state) => {
                                                state.working_sa2_point_in_polygon = false
                                                state.abm_locations = locations
                                            })
                                            // currentStore.fnSetKeyValue('abm_locations', locations)
                                        }).catch(err => {
                                            console.warn('error', err)
                                        })

                                    }
                                },
                                (err, url) => {
                                    console.warn(`ERROR LOADING ${url}`, err)

                                    currentStore.fnCallback((state) => {
                                        state.working_sa2_point_in_polygon = false
                                    })
                                }
                            )
                        }
                    }
                ]
            }
        },

        {
            id: 'abm_x_auckland_20241209',
            title: "Agent Based Modelling - Explorer - Auckland",
            description: `This layer allows users to explore the locations, agents and diaries used in the underlying New Zealand - Auckland agent based modelling data set.
            
There are a number of ways to interact with the data:

- Users can click on an area of the map to select a suburb (sa2), which will display locations within that suburb, such as supermarkets, restaurants and businesses.
- Users can zoom in to a small area then click 'load agents' to display a selection of agents who reside or travel through the map bounds according to their synthetic diary. 
    - Use the timeline to animate the diary over 24 hours.
    - Click on an agent on the map to view the agent diary.
`,
            dataCollections: ['nz_modelling'],
            twins: ['nz'],
            thumbnail: {filename: "/dt_auckland.png", width: '300', height: '220' },
            config: {
                datasetsToUnload: ['abm_x_wellington_20240913'],
                map_layer: <MapABM model='auckland_20241209'  key='mapABM' />,
                left_menu_data_source: <LeftMenuABM model_id='auckland_20241209' key='LeftMenuABM' />,
                map_event_callbacks: [
                    {
                        event_type: "LEFT_CLICK",
                        callback: (map_event, currentStore) => {
                            console.log(`ABM map event ${myStore.selected_areas.sa2.length} .. LAT:${map_event.latitude} LNG:${map_event.longitude}`, map_event)

                            if (currentStore.selected_areas['sa2'].length > 0) {
                                // console.log("PREVENTING ***** propogation as sa2 is selected.", currentStore.selected_areas)
                                return
                            } 
                            console.log("NO SA2 selected, so searcing for one:", myStore.selected_areas)
                            
                            currentStore.fnCallback((state) => {
                                state.working_sa2_point_in_polygon = "Searching for SA2..."
                            })
                            

                            // get SA2 at these coordinates
                            map_point_in_polygon(
                                'sa2_2022',
                                map_event.latitude,
                                map_event.longitude,
                                (geo_result) => {
                                    console.warn('callback goes here', geo_result)
                                    currentStore.fnCallback((state) => { 
                                        state.abm_locations = [] 
                                        if (geo_result?.area) {
                                            console.log(`Clicked in ${geo_result.area.area_name} (${geo_result.area.area_code})`)
                                            state.selected_areas['sa2'] = [geo_result.area]
                                        } else {
                                            console.log("no area")
                                            state.selected_areas['sa2'] = [] 
                                        }
                                        state.working_sa2_point_in_polygon = (geo_result?.area ? "Loading locations..." : false)
                                    })

                                    // finally - load sa2 location info from API
                                    if (geo_result?.area) {
                                        console.log(`loading locations for ${geo_result.area.area_code}`)
                                        
                                        const url = `${config.dev_api_url}/get_sa2_locations/auckland_20241209/${geo_result.area.area_code}`
                                        console.log('loading locations from ', url)

                                        axios
                                        .get(url, myStore.api_axios_header)
                                        .then((response) => {
                                            console.log("recceived ", response.data)

                                            // parse locations and replace nulls here:

                                            var locations = response.data.locations

                                            // handle locations with null for location_name
                                            locations = locations.map((location, ix) => {
                                                location = Object.assign({}, location) // locations starts off as read only
                                                location.location_name = (location?.location_name ? location.location_name : location.location_id)
                                                return location
                                            })
                                            currentStore.fnCallback((state) => {
                                                state.working_sa2_point_in_polygon = false
                                                state.abm_locations = locations
                                            })
                                            // currentStore.fnSetKeyValue('abm_locations', locations)
                                        }).catch(err => {
                                            console.warn('error', err)
                                        })

                                    }
                                },
                                (err, url) => {
                                    console.warn(`ERROR LOADING ${url}`, err)

                                    currentStore.fnCallback((state) => {
                                        state.working_sa2_point_in_polygon = false
                                    })
                                }
                            )
                        }
                    }
                ]
            }
        },

        {
            title: "Agent Based modelling - NZ Routing",
            description: `This option allows users to interact with an agent-based routing model for New Zealand, utilizing data from the Integrated Data Infrastructure (IDI), OpenStreetMap (OSM), and the New Zealand address database. The simulation currently focuses on the Wellington region, covering a sample time period during morning commutes.

Users can explore the model to see which agents visit specific locations or pass through particular areas, offering valuable insights into traffic flow and commuting behaviors.

This model runs at a minute-by-minute resolution and is currently limited to the Wellington region`,
            video: 'beehive_commute.mp4',
            dataCollections: ['nz_modelling'],
            twins: ['nz'],
            config: {
                map_layer: <MapABMRouting key='map_abm_routing' />,
                left_menu_data_source: <LeftMenuABMRouting key='LeftMenuABMRouting' />,
            }
        },
        {
            title: "Agent Based modelling - NZ Routing - Advanced",
            description: `This option allows users to interact with an agent-based routing model based on the SUMO (Simulation of Urban MObility) open source traffic simulation package

This model runs at a second-by-second resolution and is currently limited to demonstrating traffic flow between the Beehive and the National Library in Wellington

The SUMO package offers many options to simulate advanced traffic flows, including re-routing due to roadworks, and can accomodate various transport types such as pedestrians, cyclists and differing modes of public transport.

[SUMO user documentation](https://sumo.dlr.de/docs/index.html) (external link)`,
            dataCollections: ['nz_modelling'],
            twins: ['nz'],
            video: 'sumo_beehive_overhead.mp4',
            // thumbnail: {filename: "classic_dt.png", width: '300', height: '300' },
            config: {
                map_layer: [
                    // <MapSumo key='map_sumo' sumoModelId='1' />
                    <LazyComponent component="MapSumo"  key='map_sumo' sumoModelId='1' />,
                ],
                // left_menu_data_source: <LazyComponent component="ClassicDTMenu" />,
                map_event_callbacks: [
                ]
            }
        },
        {
            title: "Sample Pathogen X Outbreak",
            description: "Demonstration of how three scenarios could pan out when seeding a novel infectious disease outbreak based around Silverstream, Upper Hutt.",
            dataCollections: ['nz_modelling'],
            twins: ['nz'],
            thumbnail: {filename: "zombie_scenario.jpg", width: '300', height: '300' },
            config: {
                map_layer: [
                    // <MapOutbreak model='dt_demo_v2.1' />, 
                    // <MapOutbreakSA2 model='dt_demo_v2.1' />,
                    <LazyComponent key="MapOutbreak" component="MapOutbreak" model="dt_demo_v2.1" />,
                    <LazyComponent key="MapOutbreakSA2" component="MapOutbreakSA2" model="dt_demo_v2.1" />
                ],
                left_menu_data_source: 
                    // <LeftMenuOutbreak model='dt_demo_v2.1' />,
                    <LazyComponent component="LeftMenuOutbreak"  model='dt_demo_v2.1' />,
                // map_event_callbacks: [
                // ]
            }
        },

        

        {
            title: "Regional time series scenarios",
            description: "Multivariate time series and causal discovery over health, environmental and social factors.",
            dataCollections: ['nz_modelling'],
            twins: ['nz'],
            // thumbnail: {filename: "classic_dt.png", width: '300', height: '300' },
            youtube: 'idNyG_R0YiA?si=NMzdeksQZAdcYMgM',
            config: {
                map_layer: [
                    <LazyComponent component="ClassicDTMap" key='classic_dt_map' />,
                    <LazyComponent component="ClassicNetwork" key='classic_network' />,
                    <LazyComponent component="ClassicMapKey" key='classic_map_key' />,
                ],
                left_menu_data_source: <LazyComponent component="ClassicDTMenu" />,
                map_event_callbacks: [
                ]
            }
        },
        // {
        //     title: "QQQQQQQQ",
        //     description: "WWWWWWW",
        //     dataCollections: ['nz_infrastructure'],
            // source: "LAWA",
            // sourceURL: "https://www.lawa.org.nz/download-data",
        //     dateRange: "Realtime",
        //     twins: ['nz'],
        // },
    ]


    const SelectDataSetElement = useMemo(() => {
        if (!myStore.selected_twin) {
            console.warn("mystore selected twin id nULL!", myStore.selected_twin)
            return null
        }

        const elements = []

        const dataCollectionsForSelectedTwin = allDataCollections.filter((dataCollection, index) => {
            return (dataCollection.twins.includes(myStore.selected_twin.id))
        })

        var number_of_datasets = 0

        dataCollectionsForSelectedTwin.map((dataCollection, collection_x) => {
            elements.push(<div 
                    key={`dc_${dataCollection.id}`} 
                    style={{fontWeight:'600', fontSize:'1.3em', paddingLeft: '10px', marginTop: (collection_x > 0 ? '1em':'')}}
                >
                    {`${dataCollection.title}`}
                </div>)

            const dataSets = allDataSets.filter((dataSet, index) => {
                return (dataSet.dataCollections.includes(dataCollection.id) & dataSet.twins.includes(myStore.selected_twin.id))
            })

            if (dataSets.length > 0) {
                number_of_datasets += dataSets.length
                dataSets.map((dataSet, datasetIndex) => {
                    // for now we have to create a temporary dataSetId. This will later come via the API

                    if (! dataSet?.id) {
                        dataSet.id = `${dataCollection.id}_ds_${datasetIndex}`
                    }

                    // const dataSetId = `${dataCollection.id}_ds_${datasetIndex}`
                    // dataSet.id = dataSetId
                    const dataSetSelected = myStore.selected_datasets.some(e => e.id == dataSet.id)
                    elements.push(<div 
                            key={`dc_${dataSet.id}`}
                            style={{ 
                                fontWeight: (
                                    dataSetSelected
                                    ? 400
                                    : 300
                                ), 
                                color: (dataSetSelected ? '#222222' : '#333333' ),
                                cursor: 'pointer',
                                borderLeft: (
                                    dataSetSelected
                                    ? "5px solid #0d6efd"
                                    : null
                                ),
                                padding: `5px 0px 5px ${(
                                    dataSetSelected
                                    ? '5px'
                                    : '10px'
                                )}`,
                                backgroundColor: (
                                    (candidateDataSet && candidateDataSet?.id == dataSet.id)
                                    ? '#EEEEEE'
                                    : null
                                ),
                                marginBottom: '.25em'
                            }}
                            onClick={() => setCandidateDataSet(dataSet)}
                        >
                            {dataSet.title}
                            { dataSetSelected && <CheckCircle style={{color: '#00CC33', marginLeft:'10px'}} />}
                        </div>)
                })
            }


        })

        setDatasetFooterMessage(`${number_of_datasets} datasets available across ${dataCollectionsForSelectedTwin.length} collections`)

        return () => <div style={{ marginLeft: '-10px' }}>
            {elements}
            </div>
    }, [myStore.selected_twin, myStore.selected_datasets, candidateDataSet])

    const loadCandidate = () => {
        console.log("loading candidate", candidateDataSet)

        // add candidateDataSet to selected datasets
        myStore.fnCallback((state) => {
            loadCandidateCallback(state, candidateDataSet)
        })

        console.log('loadCandidate finished, closing.')
        handleClose()
    }

    const unloadCandidate = () => {
        console.log('unloadCandidate called')
        myStore.fnCallback((state) => {
            console.log(`Removing ${candidateDataSet.id}`, current(state.map_layers))

            // remove dataset and any assoicated map events
            state.selected_datasets = state.selected_datasets.filter(e => e.id != candidateDataSet.id)
            state.map_events = state.map_events.filter(v => v.dataset_id != candidateDataSet.id)

            console.log("CDS CONFIG", candidateDataSet?.config?.map_layer)

            // remove from map if appropriate
            if (state.map_layers.hasOwnProperty(candidateDataSet.id)) {
                console.log(`Removing map layer for ${candidateDataSet.id}`)
                delete state.map_layers[candidateDataSet.id]
            }

            // remove from left menu if appropriate
            if (state.left_menu_data_sources.hasOwnProperty(candidateDataSet.id)) {
                console.log(`removing ${candidateDataSet.id} from left menu`)
                delete state.left_menu_data_sources[candidateDataSet.id]
            }
        })
    }

    const DataSetDescription = useMemo(() => {
        if (! candidateDataSet) {
            return () => <div>
                    <div style={{
                        position: 'relative',
                        width: '100%',
                        height: '0',
                        paddingBottom: '56.25%'
                    }}>
                        <iframe src="https://www.youtube.com/embed/1NyRAWzb8mY?si=kqub6_rYBWA6UL8z" 
                            title="YouTube video player" 
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%'
                                }}></iframe>
                </div>
            </div>
        }

        console.log("currently selected datasets:", myStore.selected_datasets)


        console.log("returning updated candiateDataSet UI", candidateDataSet)
        return function DSD() { 
            return <>
                <h4>{candidateDataSet.title}</h4>
                <div>
                    { candidateDataSet.video && <video autoPlay muted loop style={{width:'100%'}}>
                        <source src={candidateDataSet.video} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video> }
                    {
                        candidateDataSet.thumbnail && 
                        <div style={{backgroundImage: `url(${candidateDataSet.thumbnail.filename})`, width:'100%', height: '200px', backgroundSize:'cover', backgroundPosition:'center' }}
                            />
                    }
                    {
                        candidateDataSet.youtube &&
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            height: '0',
                            paddingBottom: '56.25%'
                        }}>
                            <iframe src={`https://www.youtube.com/embed/${candidateDataSet.youtube}`}
                                title="YouTube video player" 
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                                style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%'
                                    }}></iframe>
                        </div>
                    }
                    <ReactMarkdown remarkPlugins={[gfm]}>
                        {candidateDataSet.description}
                    </ReactMarkdown>
                </div>
                <Row style={{marginTop:'2em'}}>
                    <Col>
                        <Button variant='secondary' onClick={() => setCandidateDataSet(null)}>Cancel</Button>
                    </Col>
                    <Col style={{textAlign:'right'}}>
                        {
                            (
                                (  myStore.selected_datasets.some(e => e.id == candidateDataSet.id))
                                ? <Button variant='danger' onClick={unloadCandidate}>Remove</Button>
                                : <Button onClick={loadCandidate}>Select</Button>
                            )
                        }                    
                    </Col>
                </Row>
            </>
        }
        


    },[candidateDataSet, myStore.selected_datasets])

    return <Modal 
            show={myStore.status_show_modal_data} 
            onHide={handleClose} 
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
                fontSize:".8em"
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title 
                    style={{
                        width:'100%',
                        }}>
                    <Container>
                        <Row>
                            <Col md={8}>Data Catalogue - {myStore.selected_twin.name}</Col>
                            <Col md={4} style={{ 
                                        textAlign:'right',
                                    }}>
                                <Button onClick={() => {
                                    myStore.fnSetKeyValue('status_show_modal_data', false)
                                    myStore.fnSetKeyValue('status_show_modal_upload',true)
                                }}>Upload data</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col md={5}>
                            <SelectDataSetElement />
                        </Col>
                        <Col style={{
                            borderLeft: '1px solid #2EA2F5'
                        }}>
                            <DataSetDescription />
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <>
                    {dataSetFooterMessage}
                </>
            </Modal.Footer>
        </Modal>

}

export default ModalData