import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'

const Licence = () => {
    
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('LICENCE.md')
            .then((response) => response.text())
            .then((text) => setContent(text));
    }, []);

    return <div style={{
        maxWidth: '900px',
        width: '100%',
        margin: 'auto',
        padding: '20px',
        display: 'block'
      }}>
        <ReactMarkdown>
            {content}
        </ReactMarkdown>
    </div>
}

export default Licence