import React from "react";
import { Button } from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import { useNavigate } from "react-router-dom";
import LazyComponent from "./components/LazyComponent";

const Disclaimer = () => {
  const navigate = useNavigate();

  const acceptDisclaimer = () => {
    localStorage.setItem('acceptedDisclaimer', 'true')
    
    if (localStorage.getItem('dt_token')) {
      navigate('/');
    } else {
      navigate('/login');
    }
  }

//   const markdown = `
  
// # Legal Disclaimer

// ## Introduction

// Welcome to our Digital Twin platform. By accessing or using this platform, you agree to be bound by the terms and conditions set forth in this disclaimer. Please read this disclaimer carefully before proceeding. If you do not agree with any part of this disclaimer, you must not use this platform.

// ## Synthetic Data Explanation

// The data presented in this Digital Twin is **completely synthetic**. Synthetic data refers to data that is artificially generated rather than obtained by direct measurement. This synthetic data is derived from various data sources and is designed to be statistically representative at a suburb level. This means that while the data aims to reflect the general characteristics and trends of a given suburb, it does not correspond to real individuals or specific real-world events.

// ### What Does Synthetic Data Mean?

// Synthetic data is created using algorithms and simulations to mimic the statistical properties of real-world data. It is used to model and analyze scenarios without compromising the privacy of individuals. In our Digital Twin, synthetic data is used to provide insights and visualizations that are representative of broader trends and patterns within a suburb. However, it is important to note that:

// - **Individual agents within the Digital Twin are seeded from suburb-level datasets**. This means that while the data represents general trends at the suburb level, it does not and cannot identify any specific individuals.
// - **No real personal data is used**. The synthetic data is generated to ensure that individual privacy is maintained and that no real personal information is included.

// ## Purpose and Use of Local Storage

// To enhance your experience on our platform, we use localStorage for functional purposes. Specifically, we use localStorage to:

// 1. **Record that users have accepted this disclaimer**. This ensures that you do not have to repeatedly acknowledge the disclaimer each time you visit the platform.
// 2. **Store user tokens and other session-related information**. This allows us to provide a seamless and personalized experience by remembering your login information and other preferences.

// By using our platform, you consent to the use of localStorage as described above. If you do not agree to the use of localStorage, you should adjust your browser settings accordingly or refrain from using the platform.


// ## Conclusion

// This disclaimer is intended to provide a comprehensive explanation of the nature of the synthetic data used in our Digital Twin and the purpose of the cookies we employ. We encourage you to read this disclaimer in full to understand the terms and conditions of using our platform. However, we understand that legal disclaimers can be lengthy and detailed. By clicking "Accept," you acknowledge that you have read, understood, and agreed to the terms set forth in this disclaimer.

// Thank you for your attention and cooperation.

// `

  return <div style={{
    maxWidth: '900px',
    width: '100%',
    margin: 'auto',
    padding: '20px',
    display: 'block'
  }}>
    <LazyComponent component='TermsOfUse' key='TermsOfUseKey' />
    <Button onClick={acceptDisclaimer}>Accept</Button>
  </div>
}

export default Disclaimer;