import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"

const About = () => {
    return <div>
        <p>
            ESR's Digital Twin project aims to revolutionise data-driven decision-making across multiple domains. At its core, this initiative creates virtual replicas of complex physical systems, leveraging advanced AI and machine learning algorithms. The backbone of our approach is an orchestration of AI predictive modelling and a synthetic population algorithm, derived from census and survey datasets, which powers our large-scale simulations.
        </p>
        <p>
            Starting with Covid-19, this sophisticated platform was implemented to model the interplay between health metrics, socioeconomic factors, and environmental drivers, integrating state-of-the-art AI predictive modelling techniques such as time series forecasting and causal discovery. By simulating the behaviour and interactions of New Zealand's entire population, we are delivering virtual outbreak modelling for measles, providing critical insights to the Public Health Agency.
        </p>
        <p>
            To bring these simulations to life, we have implemented a cutting-edge 3D geospatial visualisation platform as the backbone of our Digital Twin user interface. This immersive and intuitive experience enhances data exploration and analysis, making complex information accessible and actionable.
        </p>
        <p>
            Currently, we are expanding the adaptability of our digital twin technology. For example, we are developing a Digital Twin of Tonga to analyse water resource management's role in climate change resilience, showcasing how this technology can address global challenges beyond public health.
        </p>
        <p>
            The versatility of our platform is garnering significant interest from both public and private sectors as we tailor it into a commercial product. As we continue to refine and expand our digital twin capabilities, we are not just observing systems – we are predicting their future, optimising their performance, and delivering AI-powered decision support to our stakeholders.
        </p>
    </div>
}

export default About