import useDataStore from "../helpers/dataStore"

const TongaTooltip = ({tooltip}) => {

    if (! tooltip) {
        return null
    }

    console.log(tooltip)
  

    return <div style={{
        border: '1px solid chartreuse',
        padding: '3px 5px',
        marginLeft: '10px',
        position: 'absolute',
        backgroundColor: '#FFFFFFCC',
        top: tooltip.y,
        left: tooltip.x,
        zIndex: 9000
    }}>{tooltip.message}</div>
}

export default TongaTooltip