import {create } from 'zustand';
import { current, produce } from 'immer';

import config from './config';

const useDataStore = create((set) => ({
    cameraPosition: config.defaultCameraPosition,
    
    chatbot: {
        shown: false,
        name: 'Alvaro2.0',
        avatar: 'avatars/image (13).png',
        history: [{
            message: "Kia ora! I'm the ESR chatbot. Ask me anything.",
            from: 'chatbot',
        }],
        busy: false,
        url: config.chatbot_url,
        speech: null,
    },

    // config for classic DT
    classic: {
        // API_URL: "http://psyduck:28000/",
        API_URL: 'https://dt-pioneer.azurewebsites.net',
        chartConfig: null,
        currentTime: null, // time from Viewer - updated via callback set in ClassicDTMenu
        data: null,     // simulated data
        geoSelected: ['Auckland'], // selected region(s)
        geoMapOverlay: {
            name: 'Region', 
            gisId: '291', 
            geo_key: 'region_2023'
        },
        indicator: {
          name: 'Covid cases',
          id: 'case_pp',
          description: 'Covid cases count',
          frequency: 'daily',
          source: "OBI"
        },
        // indicator2: {
        //     id: -1
        // },
        indicator2: null,
        meta: null,     // meta (indicators + covariates)
        modalState: {
            show: false,
            value: -1,
            xDate: null,
            seriesName: null
        },
        modelling: {
          data: { data: null, modelParams: null },
          timeout: null,
          url: null,
          start_time: null,
          duration: null,
          params: null,
          readyToRun: false,
          working: false
          //   e.g.
          //   {
          //     date: props.modalState.xDate,
          //     dayOffset: dayOffset,
          //     indicator: props.modalState.indicator,
          //     regionName: props.modalState.regionName,
          //     proportionIncreased: propIncrease
          //   }
        },
        n_ile: 4,
        origData: null, // original data
        
        regions: null,  // regions
        showOriginalData: false,
    },

    // use custom_state in xlsx upload to save states
    custom_state: {}, 

    map_layer_selected_area: null,

    not_very_secure_secret: 'Artemis',

    well_geojson: "",

    map_viewport: { bounds: null },

    map_date: config.map_date,
    map_show_clock: false,

    map_custom_layers: {},

    search_results: {
        // locations: [],
        // datasets: []
    },

    selected_well: null,

    selected_island: null,
    selected_district: null,
    selected_village: null,

    selected_twin: config.twins.nz,

    status_show_menu_left: true,
    status_show_menu_right: false,
    status_show_GeoWells: false,
    status_show_GeoWaterTower: false,
    status_show_GeoWaterBore: false,
    status_show_GeoVillages: false,

    status_show_modal_data: false,
    status_show_modal_intro: true, // change if you want to force pw
    status_show_modal_upload: false,

    selected_datasets: [],

    selected_areas: {
        'sa2': []
    },

    tour: {
        shown: false,
        config: null
    },

    abm_locations: [],
    abm_diaries: null,
    abm_selected: {
        agent: null,
        location: null,
    },

    map_3d_tiles: false,
    map_events: [],
    map_layers: {},

    left_menu_data_sources: {},

    map_margin_right: 0,
    map_margin_left: 400,

    viewerRef: null,
    viewerCesiumElement: null, // map viewer cesiumElement

    tooltip: null,
    urlCache: {},

    fnInit: () => set(produce((state) => {
        console.log("initiating dataStore")
    })),

    fnCallback: (callback) => set(produce((state) => callback(state))),
    
    fnSetKeyValue: (key, value) => {
        set(
            produce((state) => {
                state[key] = value
            })
        )
    },
    
    fnUrlCacheAppend: (url, data) => {
        set(produce((state) => {
            state.urlCache[url] = data
        }))
    }
}))


export default useDataStore;