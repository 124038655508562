import { current } from "immer"

const loadCandidateCallback = (state, candidateDataSet) => {
    // should search here by ID to ensure we don't push the same dataset twice.
    if (state.selected_datasets.some(e => e.id == candidateDataSet.id)) {
        console.warn("candidate dataset is already selected", candidateDataSet.id)
        return
    }

    console.warn(`Candidate dataset ${candidateDataSet.id} added`, candidateDataSet)
    state.selected_datasets.push(candidateDataSet)

    if (candidateDataSet?.config?.map_layer) {
        // console.log(`Adding map layer for ${candidateDataSet.id}`)
        state.map_layers[candidateDataSet.id] = candidateDataSet.config.map_layer
    }
    
    if (candidateDataSet?.config?.left_menu_data_source) {
        // console.info(`** adding ${candidateDataSet.id} to left menu.`)
        state.left_menu_data_sources[candidateDataSet.id] = candidateDataSet.config.left_menu_data_source
    }

    if (candidateDataSet?.config?.map_event_callbacks) {
        // add the event callbacks to store.
        // console.log(`ADDING ${candidateDataSet.config.map_event_callbacks.length} CALLBACKS`)
        
        candidateDataSet.config.map_event_callbacks.map((map_event_callback, ix) => {
            // event_config is a dict, with event_type and callback. need to add dataset_id here before pushing to map_events.
            const event_config = Object.assign({}, map_event_callback, {dataset_id: candidateDataSet.id})
            // console.log("adding event_config", event_config)
            state.map_events.push(event_config)
        })
        // console.log(`store now contains ${state.map_events.length} callbacks`, current(state.map_events))
    }

    if (candidateDataSet?.config?.custom_state) {        
        // console.log(`Adding custom state '${candidateDataSet.id}'`, candidateDataSet?.config?.custom_state)
        state.custom_state[candidateDataSet.id] = candidateDataSet?.config?.custom_state
    }

    if (candidateDataSet?.config?.datasetsToUnload) {
        // unload these datasets 

        console.log("unloading", candidateDataSet?.config?.datasetsToUnload)

        candidateDataSet?.config?.datasetsToUnload.map((dataset_id, dx) => {
            console.log(`unloading [${dataset_id}]`)

            console.log(`Removing ${dataset_id} from`, current(state.map_layers))

            // remove dataset and any assoicated map events
            state.selected_datasets = state.selected_datasets.filter(e => e.id != dataset_id)
            state.map_events = state.map_events.filter(v => v.dataset_id != dataset_id)

            // remove from map if appropriate
            if (state.map_layers.hasOwnProperty(dataset_id)) {
                console.log(`Removing map layer for ${dataset_id}`)
                delete state.map_layers[dataset_id]
            }

            // remove from left menu if appropriate
            if (state.left_menu_data_sources.hasOwnProperty(dataset_id)) {
                console.log(`removing ${dataset_id} from left menu`)
                delete state.left_menu_data_sources[dataset_id]
            }
        })
    }
}

export default loadCandidateCallback;