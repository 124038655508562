import { useMemo } from "react";
import useDataStore from "../helpers/dataStore";

const MapLayers = () => {
    const myStore = useDataStore()

    const map_layer_elements = useMemo(() => {
        console.log("myStore.map_layers have changed..", myStore.map_layers)

        if (! myStore?.map_layers) {
            return null
        }

        return Object.values(myStore.map_layers)

    },[myStore?.map_layers])


    return map_layer_elements

}


export default MapLayers;