import axios from "axios"
import config from "./config"

const map_point_in_polygon = (area_type, latitude, longitude, callback, errorCallback) => {
    const url = `${config.dev_api_url}/get_shape_from_point?latitude=${latitude}&longitude=${longitude}&geo_key=${area_type}`

    const user_token = localStorage.getItem('dt_token')

    const api_axios_header = {
        headers: {
            Authorization: `Bearer ${user_token}`
        }
    }


    axios.get(url, api_axios_header)
    .then((response) => {
        callback(response.data)
    }).catch((err) => {
        console.warn("ERROR", err)
        console.info('url', url)
        errorCallback(err, url)
    })
}

export default map_point_in_polygon