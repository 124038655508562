/* eslint-disable react/display-name */
import React from "react"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import useDataStore from "../helpers/dataStore"
import { useMemo } from "react"
import { Link } from "react-router-dom"

const ModalESRAttribitions = () => {
    const myStore = useDataStore()

    const MapBoundsInfo = useMemo(() => () => {
        if (!myStore?.map_viewport?.bounds) {
            return null
        }

        return <div>
                <h3>Map bounds</h3>
                <div>North{myStore?.map_viewport?.bounds.north}</div>
                <div>East{myStore?.map_viewport?.bounds.east}</div>
                <div>South{myStore?.map_viewport?.bounds.south}</div>
                <div>West{myStore?.map_viewport?.bounds.west}</div>
            </div>
    }, [myStore?.map_viewport?.bounds])

    const mapLayerList = useMemo(() => {
        if (! myStore.selected_datasets.length) {
            return null
        }

        // return info about datasets.
        return <div style={{
                fontSize: '.9em',
                marginTop: '1em',
            }}>
                <div>Showing {myStore.selected_datasets.length} dataset{myStore.selected_datasets.length > 1?"s":""}:</div>
                {
                    myStore.selected_datasets.map((dataset,ix) => {
                        return <div 
                            key={`dataset_list_${ix}`}  
                            style={{
                                whiteSpace: 'nowrap', // stop text from wrapping
                                overflow:'hidden', // hide overflowing text
                                textOverflow: 'ellipsis' // add '...' at the point where text is cropped.
                        }}>- {dataset.title}</div>
                    })
                }
        </div>
    },[myStore.selected_datasets])

    const logout = function() {
        console.log("LOG OUT CLICKED")

        localStorage.removeItem('dt_token')
        localStorage.removeItem('dt_user')
        localStorage.removeItem('acceptedDisclaimer')

        // Optionally, you can redirect the user to the login page
        window.location.href = '/';
    }

    const UserInfo = useMemo(() => {
        if (! myStore?.dt_user) {
            return <div>No user info</div>
        }

        return () => <div>
            <h3>User</h3>
            <p>Logged in as <b>{myStore.dt_user?.forename}</b></p>
            <p>{myStore.dt_user?.email}</p>
            <Button onClick={logout}>Logout</Button>
        </div>
    },[myStore?.dt_user])

    const GetModal = useMemo(() => () => {
        if (! myStore.status_show_esr_attributions) {
            return null
        }

        return <Modal 
            show={myStore.status_show_esr_attributions} 
            onHide={() => { 
                myStore.fnSetKeyValue('status_show_esr_attributions',false)
            }} 
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
                fontSize:".8em"
            }}
        >
        <Modal.Header closeButton>
            <Modal.Title 
                style={{
                    width:'100%',
                    }}>
                <Container>
                    <Row>
                        <Col>ALMA Attributions and Map Settings</Col>
                    </Row>
                </Container>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col>
                        <h3>Map layers</h3>
                        <Form.Check 
                            type='switch'
                            id='3dmap_checkbox' 
                            label='Use 3d map' 
                            checked={myStore.map_3d_tiles}
                            onChange={() => {
                                console.log("3d tile setting toggled")
                                myStore.fnCallback((state) => {
                                    state.map_3d_tiles = ! state.map_3d_tiles
                                })
                            }}
                        />
                        
                        {Object.keys(myStore.map_layers).map((layer_name, lnx) => {
                            return <div key={`layername_${lnx}`}>{layer_name}</div>
                        })}

                        
                        {mapLayerList}
                        
                    </Col>
                    <Col style={{
                        borderLeft: '1px solid #2EA2F5'
                    }}>
                        <MapBoundsInfo />
                    </Col>
                    <Col style={{
                        borderLeft: '1px solid #2EA2F5'
                    }}>
                        <UserInfo />
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Link to='licence'>Licence Information</Link>
        </Modal.Footer>
        </Modal>
    },[myStore.status_show_esr_attributions, myStore.map_3d_tiles, myStore.map_layers])

    return <GetModal />
}

export default ModalESRAttribitions