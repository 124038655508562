import React, { useEffect, useState } from "react";
import useDataStore from "../helpers/dataStore";
import config from "../helpers/config";
import { Cartesian3, Color, DistanceDisplayCondition, HeightReference, HorizontalOrigin, JulianDate, NearFarScalar, PolylineOutlineMaterialProperty, Rectangle, SampledPositionProperty, VerticalOrigin } from "cesium";
import { CustomDataSource, Entity, Polyline, PolylineCollection, PolylineGraphics, RectangleGraphics } from "resium";

const generateSmileyIcon = (color) => `
  <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="45" stroke="black" stroke-width="5" fill="${color}" />
    <circle cx="35" cy="40" r="5" fill="black" />
    <circle cx="65" cy="40" r="5" fill="black" />
    <path d="M 35 60 Q 50 75 65 60" stroke="black" stroke-width="5" fill="transparent" />
  </svg>
`;

const MapABMDiaries = () => {
    const myStore = useDataStore()
    const [agent_elements, set_agent_elements] = useState(null)
    
    useEffect(() => {
        const diaries = myStore.abm_diaries

        console.log('diaries', diaries)

        if (! diaries) {
            return null
        }

        const elements = []

        const positions = [
            new Cartesian3.fromDegrees(diaries.bounds.minLng, diaries.bounds.minLat),
            new Cartesian3.fromDegrees(diaries.bounds.minLng, diaries.bounds.minLat),
            new Cartesian3.fromDegrees(diaries.bounds.maxLng, diaries.bounds.minLat),
            new Cartesian3.fromDegrees(diaries.bounds.maxLng, diaries.bounds.maxLat),
            new Cartesian3.fromDegrees(diaries.bounds.minLng, diaries.bounds.maxLat),
            new Cartesian3.fromDegrees(diaries.bounds.minLng, diaries.bounds.minLat),
            new Cartesian3.fromDegrees(diaries.bounds.maxLng, diaries.bounds.minLat),
        ]

        elements.push(            
            <Entity key='polyline_MapABMDiaries'>
                <PolylineGraphics
                    positions={positions}
                    width={7}
                    material={Color.RED}
                    clampToGround={true}
                    />
            </Entity>
        )

        // deal with locations
        Object.keys(diaries.locations).map((location_id,ix) => {
            const location = diaries.locations[location_id]
            // console.log(`location ${location_id}`, location)

            const randomMultiplier = 0.00005
            const randomOffsetX = Math.random() * randomMultiplier - randomMultiplier/2
            const randomOffsetY = Math.random() * randomMultiplier - randomMultiplier/2
            const position = Cartesian3.fromDegrees(location.longitude+randomOffsetX, location.latitude+randomOffsetY)

            const location_type_config = (config.location_types[location.location_type] ? config.location_types[location.location_type] : config.location_types['default'])

            const point = { 
                // pixelSize: 30, 
                pixelSize: 5 * location_type_config.radius_multiplier, 
                color: Color.fromAlpha(location_type_config.cesiumColour, .96),
                heightReference: HeightReference.CLAMP_TO_GROUND,
                disableDepthTestDistance: Number.MAX_SAFE_INTEGER
            }

            elements.push(<Entity
                key={`abm_diary_location_${ix}_${location_id}`}
                position={position}
                point={point}
                clampToGround={true}
                description={`<h3 style='color:${location_type_config.htmlColour}'>${location.location_type}</b></h3><p>${location_id}</p>`}
                onClick={(event_cartesian2_position, target) => {
                    console.log("CLICKED ON ", location)
                    myStore.fnSetKeyValue('abm_selected_location', location)

                }}
            />)
        })

        const my_date = new Date()
        const map_date = my_date.toJSON().slice(0, 10)

        // deal with agents, who move over time...
        Object.keys(diaries.agents).map((agent_id, ax) => {
            const agent = diaries.agents[agent_id]

            const agent_random_color = Color.fromRandom({
                maximumRed: 1,
                maximumGreen: 1,
                minimumGreen: .75,
                minimumRed: .75,
                alpha: 1
            })

            const key = `journey_${ax}_${agent_id}`
            const position_property = new SampledPositionProperty()
            const positions_for_debug= []
            
            // iterate through diary entries
            agent.map((entry, lx) => {
                if (! entry['location_id']) { return }

                const location = diaries.locations[entry['location_id']]
                if (! location?.longitude) { return }

                // sort out position
                const position = Cartesian3.fromDegrees(location.longitude, location.latitude)

                const arrival_min = (entry['start_hour'] == 0 ? 0 : 10) 
                const arrival_time_str = map_date + `T${(entry['start_hour']<10?'0':'')}${entry['start_hour']}:${arrival_min<10?'0':''}${arrival_min}:00Z`
                const arrival_time = new JulianDate.fromIso8601( arrival_time_str)
                position_property.addSample(arrival_time, position)

                positions_for_debug.push({
                    time: arrival_time_str,
                    location: entry['location_id'],
                })
         
                const end_hour = entry['end_hour']

                const departure_min = (end_hour == 23 ? 59: 40)       

                const departure_time_str = map_date + `T${(end_hour<10?'0':'')}${end_hour}:${departure_min<10?'0':''}${departure_min}:00Z`
                const departure_time = new JulianDate.fromIso8601( departure_time_str )
                position_property.addSample(departure_time, position)

                positions_for_debug.push({
                    time: departure_time_str,
                    location: entry['location_id'],
                })
            })
            
            // this entity uses a boring circle.
            // const journeyEntity = <Entity
            //         description={`<h3>${key}<h3>`}
            //         // description={`<h3 style='color:${config.location_types[location.location_type].htmlColour}'>${location.location_type}</b></h3><p>${location_id}</p>`}
            //         key={key}
            //         position={position_property}
            //         point={point}
            //         clampToGround={true}
            //         onClick={(e,a) => {
            //             myStore.fnCallback((state) => {
            //                 state['abm_selected'].agent = {
            //                     agent_id: agent_id,
            //                     positions: positions_for_debug,
            //                 }
            //             })
            //         }}
            //         heightReference={HeightReference.CLAMP_TO_GROUND}
            //         />

            const journeyEntity = <Entity
                description={`<h3>${key}<h3>`}
                key={key}
                position={position_property}
                billboard={{
                    image: `data:image/svg+xml;base64,${btoa(generateSmileyIcon(agent_random_color.toCssHexString()))}`,
                    width: 50,
                    height: 50,
                    horizontalOrigin: HorizontalOrigin.CENTER,
                    verticalOrigin: VerticalOrigin.CENTER,
                    distanceDisplayCondition: new DistanceDisplayCondition(0,2000),
                    heightReference: HeightReference.CLAMP_TO_GROUND,
                    scaleByDistance: new NearFarScalar(0, 1, 2000, 0.1), // Scale down with distance
                }}
                clampToGround={true}
                onClick={(e,a) => {
                    myStore.fnCallback((state) => {
                        state['abm_selected'].agent = {
                            agent_id: agent_id,
                            positions: positions_for_debug,
                        }
                    })
                }}
                heightReference={HeightReference.CLAMP_TO_GROUND}
                />

            elements.push(journeyEntity)
        })

        // eslint-disable-next-line react/display-name
        set_agent_elements( elements )

    },[myStore.abm_diaries])

    return <CustomDataSource 
            key='abm_diary_elements'
        >
        {agent_elements}
    </CustomDataSource>
}

export default MapABMDiaries;