import { useMemo } from "react";
import useDataStore from "../helpers/dataStore";
import WellData from "./WellData";
import CensusInfo from "./CensusInfo";

function MenuRight() {
    const myStore = useDataStore()

    const element = useMemo(() => {
        if (! myStore.status_show_menu_right) {
            return null
        }
        return <div style={{
            position: 'absolute',
            right: '0px',
            top: '0px',
            bottom: '0px',
            padding:'10px 5px',
            width: '350px',
            borderLeft:'5px solid silver',
            backgroundColor: '#00000099',
            color: '#FFFFFF',
            overflow: "hidden auto"
        }}>
            <CensusInfo />
        </div>
    },[myStore.status_show_menu_right])

    return element
}

export default MenuRight