import { Container } from "react-bootstrap"
import Map from "./Map"
import useDataStore from "../helpers/dataStore"
import { Link } from "react-router-dom"
import { useMemo } from "react"

const MapContainer = () => {
    const myStore = useDataStore()

    const showAttributions = () => {
        myStore.fnSetKeyValue('status_show_esr_attributions',true)
    }

    const toggleChatbot = () => {
        myStore.fnCallback((state) => {
            state.chatbot.shown = ! state.chatbot.shown
        })
    }

    const mapAttributionsContainer = useMemo(() => {

        // var className = (
        //     myStore.map_3d_tiles 
        //     ? `cesium-attribution show` 
        //     : `cesium-attribution noshow`
        // )
        var className = 'cesium-attribution'

        return <div 
            id='cesium_attribution' 
            className={className}
            >
            <div style={{
                    verticalAlign: 'middle',
                    position: 'absolute',
                    right: '10px'
                }}>
                <a href='/disclaimer'
                    style={{
                        cursor:'pointer',
                        textDecoration: 'underline',
                        color: 'white'
                        }}>
                        Disclaimer
                </a>
                {' | '}
                <span 
                    onClick={showAttributions} 
                    style={{
                        cursor:'pointer',
                        textDecoration: 'underline'
                        }}>
                        ALMA Attributions
                </span>
                {' | '}
                <span
                    onClick={toggleChatbot} 
                    id='askChatbotLink'
                    style={{
                        cursor:'pointer',
                        textDecoration: 'underline'
                        }}>
                        Ask
                </span>
            </div>
        </div>

    },[showAttributions, toggleChatbot, myStore.map_3d_tiles ]) 


    return <div 
                id='ui_map' 
                className="map-container"
            >
            <Map creditContainer='cesium_attribution' />
            { mapAttributionsContainer }
        </div>
}

export default MapContainer