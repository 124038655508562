import React, { useEffect, useState } from 'react'
import './App.css'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Ion } from 'cesium';
import config from './helpers/config'
import MenuLeft from './components/MenuLeft'
import MenuRight from './components/MenuRight'
import TongaTooltip from './components/TongaTooltip'
import useDataStore from './helpers/dataStore'
import MapContainer from './components/MapContainer'
import ModalData from './components/ModalData'
import ModalIntro from './components/ModalIntro'
import ModalUpload from './components/ModalUpload'

Ion.defaultAccessToken = config.api_keys.cesium

// Import as a module in your JS
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ModalESRAttribitions from './components/ModalESRAttributions'
import { Button } from 'react-bootstrap'
import { List } from 'react-bootstrap-icons'
import LazyComponent from './components/LazyComponent';
import Tour from './components/Tour';

function App() {
  const myStore = useDataStore()
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMenuVisible(true);
      } else {
        // setIsMenuVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize()
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='app-container'>
      <div 
        style={{
          backgroundColor:'#434343',
          color: 'white',
          cursor: 'pointer',
          padding: '10px',
        }}  
        className="menu-toggle"
        onClick={toggleMenu}
        >
          <List /> {isMenuVisible ? 'Hide Menu' : 'Show Menu'}
      </div>
      <div className='content'>

        { isMenuVisible && <MenuLeft /> }
        <MapContainer />

      </div>
      <TongaTooltip tooltip={myStore.tooltip} />
      <Tour />
      <MenuRight />
      <ModalData />
      <ModalIntro />
      <ModalUpload />
      <ModalESRAttribitions />
      { myStore.chatbot.shown && <LazyComponent component="Chatbot" /> },
      {/* { myStore.tour.shown && <Tour /> } */}
    </div>
  )
}

export default App
