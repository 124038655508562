/* eslint-disable react/display-name */
import React, { useMemo, useRef, useState } from "react";
import useDataStore from "../helpers/dataStore";
import WellData from "./WellData";
import esr_logo from "../assets/esr_400.png"
import { Button, Col, Container, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import search from "../helpers/search";
import { CameraFlyTo } from "resium";
import { Rectangle } from "cesium";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import config from "../helpers/config";
import LeftMenuCustomSHP from "./LeftMenuCustomSHP";
import { Map, Pin } from "react-bootstrap-icons";
import { Rnd } from "react-rnd";
import { useParams } from "react-router-dom";


function MenuLeft() {
    const myStore = useDataStore()
    const searchRef = useRef()

    
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [searchOptions, setSearchOptions] = useState([]);

    // default sidebar intro section, e.g. youtube video intro. this is hidden when data is shown.
    const SidebarIntro = useMemo(() => {

        if (myStore?.selected_well) {
            return () => null
        }

        const user_map_layer_keys = Object.keys(myStore.map_custom_layers)

        // no data selected, just show intro video.
        if (myStore.selected_datasets.length == 0 && user_map_layer_keys.length == 0) {
            return () => <>
                <p style={{marginTop: '.5em'}}>Welcome to the ESR Digital Twin public preview.</p>
                <p>To get started, use the Select data option above, or use the button below to take a quick tour:</p>
                <Button variant="info" onClick={() => {
                    myStore.fnCallback((state) => {
                        console.log('showing tour')
                        state.tour.steps = config.tours.default.steps
                        state.tour.shown = true
                    })
                }}>
                    <Map /> Take the tour
                </Button>
                

                {/* <img src='public/metlink.jpg' width='100%' /> */}
                <div style={{
                    backgroundImage: "url('abm_explorer.jpg')",
                    width: '100%',
                    height: '130px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    marginTop: '1em'
                    }}></div>
                    
                <div style={{
                    fontSize: '1.1em',
                    marginTop: '1em',
                    fontWeight: 'bold',
                    color: '#434343'
                }}>Highlights</div>
                <ul>
                    <li>Population projections to 2048</li>
                    <li>Agent based modelling for 300 suburbs in Wellington region</li>
                    <li>Proof of concept visualisations for infectious disease scenarios</li>
                    <li>Traffic simulation</li>
                </ul>

                <div style={{
                    backgroundImage: "url('metlink.jpg')",
                    width: '100%',
                    height: '130px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    marginTop: '1em'
                    }}></div>

                <div style={{
                    fontSize: '1.1em',
                    marginTop: '1em',
                    fontWeight: 'bold',
                    color: '#434343'
                }}>Limitations</div>
                <p>This public preview presents limited example functionality based on a subset of ESR's modelling capabilities. Limitations include:</p>
                <ul>
                    <li>Agent based modelling limited to 300 SA2s in the Wellington region</li>
                    <li>Infectious disease spread scenarios limited to precalculated scenarios</li>
                    <li>No ability for users to upload files, save views and share data stories</li>
                </ul>
                {/* Youtube goes here */}
                {/* <iframe width="370" height="208" src="https://www.youtube-nocookie.com/embed/loIqc6ZH_Z0?si=FpnlnEu6eKXye-Vr" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen></iframe> */}
            </>
        }
        
        return () => null

    },[myStore.selected_well, myStore.selected_datasets, myStore.map_custom_layers])

    const CustomDataSourceComponents = useMemo(() => {
        return () => Object.values(myStore.left_menu_data_sources)
    }, [myStore.left_menu_data_sources, myStore.custom_state])

    const UserMapLayers = useMemo(() => {
        const user_map_layer_keys = Object.keys(myStore.map_custom_layers)
        
        // if (! user_map_layer_keys.length) {
        //     return () => null
        // }

        return () => <div key={`user_map_layer`}>
                {/* <div>{user_map_layer_keys.length} custom layers:</div> */}
                {user_map_layer_keys.map((custom_layer_id,ix) => {
                    return <LeftMenuCustomSHP layer_id={custom_layer_id} />
                })}
            </div>
    }, [myStore.map_custom_layers])

    const handleSearch = (query) => {
        setIsLoadingSearch(true);


        const url = `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=pjson&singleLine=${query}&countryCode=NZ&outFields=*&token=${config.api_keys.esri_arcgis_geolocation}`

        console.log(`getting`, url)
    
        axios.get(url).then((result) => {
            console.log("AXIOS RESULT", result.data.candidates)
    
            // myStore.fnSetKeyValue('search_results', {locations: result.data.candidates})

            setSearchOptions(result.data.candidates)
            setIsLoadingSearch(false)
        }).catch((err) => {
            console.warn("ERR", err)
            setSearchOptions([])
            setIsLoadingSearch(false)
        })
      };

    if (! myStore.status_show_menu_left) {
        return null
    }


    

    return (
        <Rnd
            default={{
                width: '400px',
                height: '100%',
            }}
            minWidth={200}
            bounds="parent"
            enableResizing={{ 
                right: true,
                left: false,
                top: false,
                bottom: false,
                topLeft: false,
                topRight: false,
                bottomLeft: false,
                bottomRight: false,
            }}
            disableDragging={true}
            style={{ position: 'relative' }}
           
        >
        <div className='menu-left-resize-handle' />
        <div className='menu-left' key='menu-left' style={{overflowX:'hidden'}}>
            <Container>
                <Row>
                    <Col xs={4}>
                        <img src={ esr_logo } alt='ESR logo' style={{
                            width: '100%',
                            margin: '1em 0',
                            fontFamily: 'monospace',
                        }} />
                    </Col>
                    <Col style={{
                        margin: 'auto'
                    }} className='align-middle'>
                        <div style={{
                            fontSize: '1.6em',
                            lineHeight: '.95em',
                            fontWeight: 'bolder',
                            color: '#3c3c3c',
                        }}>ALMA</div>
                        <div style={{
                            // color: '#424242',
                            color: '#2ea2f5',//esr blue
                            fontSize: '1.1em',
                            fontWeight: 'bold',
                            // lineHeight: '.9em',
                        }}>{myStore.selected_twin.name}</div>
                    </Col>
                </Row>
            </Container>
            
            <Container>
                <Row>
                    <Col>
                        <AsyncTypeahead
                            filterBy={() => true}
                            id='search'
                            isLoading={isLoadingSearch}
                            ref={searchRef}
                            labelKey={(option) => {
                                return option.address
                            }}
                            onChange={(options) => {
                                // location = options
                                const location = options[0]

                                if (! location) {
                                    return
                                }

                                const destination = Rectangle.fromDegrees(
                                        location.extent.xmin, 
                                        location.extent.ymin, 
                                        location.extent.xmax, 
                                        location.extent.ymax
                                    )

                                myStore.fnSetKeyValue('cameraPosition', <CameraFlyTo
                                        duration={3} 
                                        destination={destination} 
                                    />
                                )

                                searchRef.current.blur()
                                searchRef.current.clear()
                            }}
                            minLength={3}
                            onSearch={handleSearch}
                            options={searchOptions}
                            placeholder='Search for a location or a dataset'
                            renderMenuItemChildren={(option) => {
                                return (
                                    <>
                                        <div>{option.address}</div>
                                        <div style={{fontSize:'.8em',color:'#434343'}}>({option.attributes.Addr_type}, {option.attributes.Type}, {option.attributes.Subregion})</div>
                                    </>
                                )
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                    <Col>
                        <Button id='selectData' onClick={() => {
                            myStore.fnSetKeyValue('status_show_modal_data',true)
                            console.log("showing modal")
                        }}>
                            Select data
                        </Button>
                        {' '}
                        <Button variant='secondary' onClick={() => myStore.fnSetKeyValue('status_show_modal_intro', true)}>Show intro</Button>
                    
                    </Col>
                </Row>

                <SidebarIntro />

            </Container>

            <CustomDataSourceComponents />

            <UserMapLayers />

            { myStore?.selected_well && <WellData /> }
            {/* <WellData /> */}
        </div>


        </Rnd>
    );

}

export default MenuLeft