import React, { useEffect, useMemo, useRef, useState } from "react"
import { CameraFlyTo, Clock, Globe, Viewer, ImageryLayer, Cesium3DTileset, Provider  } from "resium"
import GeoWaterBore from "./GeoWaterBore"
import GeoWaterTower from "./GeoWaterTower"
import useDataStore from "../helpers/dataStore"
import GeoWells from "./GeoWells"
import GeoVillages from "./GeoVillages"
import MapEvents from "./MapEvents"
import MapLayers from "./MapLayers"
import MapCustomUserLayers from "./MapCustomUserLayers"
import { Math as CesiumMath, ClockRange, ClockStep, JulianDate, ProviderViewModel, 
    knockout, Rectangle,  Terrain,  UrlTemplateImageryProvider,  buildModuleUrl,  createWorldImageryAsync, createWorldTerrainAsync, Cesium3DTileset as CesiumCesium3DTileset,   
    ArcGisMapServerImageryProvider} from "cesium"
import config from "../helpers/config"

import { ArcGisMapService } from 'cesium';


function Map(props) {
    const myStore = useDataStore()
    const viewerRef = useRef(null)
    
    ArcGisMapService.defaultAccessToken = config.api_keys.esri_arcgis_basemaps
    // const [terrainProvider, setTerrainProvider] = useState(null)

    useMemo(() => {
        // console.log("Maps picked up a change to the selected twin", myStore.selected_twin)
        if (! myStore.selected_twin) {
            return
        }

        const newCameraPosition = <CameraFlyTo
            duration={3} 
            destination={myStore.selected_twin.default_camera_destination} 
            orientation={myStore.selected_twin.default_camera_orientation} 
            onComplete={() => {
                // console.log('finished camera change')
                myStore.fnSetKeyValue('cameraPosition', null)
            }}
        />

        myStore.fnSetKeyValue('cameraPosition', newCameraPosition)
    },[myStore.selected_twin])

    const CameraPosition = useMemo(() => {
        // console.log("Set camera position to ", myStore.cameraPosition)
        return () => myStore.cameraPosition
    }, [myStore.cameraPosition])



    const setViewport = (viewport) => {
        myStore.fnCallback((state) => {
            console.log("setting viewport=", viewport)
            state.map_viewport = viewport
        });
    }


    useEffect(() => {
        if (viewerRef.current) {
          const viewer = viewerRef.current.cesiumElement;
    
            if (! viewer) {
                return
            }
            const updateViewport = () => {
                const camera = viewer.camera;
                const rectangle = camera.computeViewRectangle();

                const bounds = {
                    'west': CesiumMath.toDegrees(rectangle.west),
                    'south': CesiumMath.toDegrees(rectangle.south),
                    'east': CesiumMath.toDegrees(rectangle.east),
                    'north': CesiumMath.toDegrees(rectangle.north)
                }

                myStore.fnSetKeyValue(
                    'map_viewport', {
                        'bounds': bounds
                    })
                };
        
            viewer.camera.moveEnd.addEventListener(updateViewport);


            

    
            return () => {
                viewer.camera.moveEnd.removeEventListener(updateViewport);
            };

          
        } else {
            console.log("VR.current is null")
        }
      }, [setViewport]);

    // useEffectOnce(() => {
    //     if (viewer.baseLayerPicker) {
    //         console.log("bLP", viewer.baseLayerPicker)
    //         window.blp = viewer.baseLayerPicker

    //         const baseLayers = viewer.baseLayerPicker.viewModel.imageryProviderViewModels;
    //         console.log("baselayers", baseLayers)
    //         window.baseLayers = baseLayers
    //     }
    // })

    useEffect(() => {
        const viewer = viewerRef.current.cesiumElement;
        if (viewer && viewer.baseLayerPicker) {

            const baseLayerPickerViewModel = viewer.baseLayerPicker.viewModel

            // Subscribe to changes in the selectedImagery property
            knockout.getObservable(baseLayerPickerViewModel, 'selectedImagery').subscribe((newImagery) => {
                console.log('Selected basemap changed to:', newImagery.name);
                // Your custom logic here
                // console.log('new imagery', newImagery)
                if (newImagery.name != 'Google 3D') {
                    myStore.fnCallback((state) => {
                        if (state.map_3d_tiles) {
                            console.log("Disabling 3d map")
                            state.map_3d_tiles = false
                        }
                    })
                }
            });


            const baseLayers = viewer.baseLayerPicker.viewModel.imageryProviderViewModels;

            // Array of names to exclude
            const excludeNames = ['Stadia', 'Blue Marble', 'Earth at night', 'Natural Earth', 'Sentinel', 'LINZ Basemap', 'Google 3D'];

            // Filter out the layers with names that include any of the excludeNames
            const filteredLayers = baseLayers.filter(layer => 
                !excludeNames.some(name => layer.name.includes(name))
            );

            // // Update the base layer picker with the filtered layers
            // viewer.baseLayerPicker.viewModel.imageryProviderViewModels = filteredLayers;


             // Add LINZ Basemap
             const linzBasemap = new ProviderViewModel({
                name: 'LINZ Basemap',
                iconUrl: buildModuleUrl('/map_provider_linz_basemap.png'),
                tooltip: 'LINZ Basemap ©LINZ CC BY 4.0 Imagery Basemap contributors',
                category: 'Cesium ion',
                creationFunction: function() {
                    
                    myStore.fnCallback((state) => {
                        state.map_3d_tiles = false
                    })

                    return new UrlTemplateImageryProvider({
                        url: 'https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=c01fewsapfztdybdnf6sww3gwx7',
                        maximumLevel: 20 // Set the maximum zoom level to 20
                    });
                }
            });
            filteredLayers.push(linzBasemap)

            // okay -> everything else in filteredLayers needs to have it's creationFunction tweaked so it runs it also disables map_3d_tiles...
            

            
            const google3dTiles = new ProviderViewModel({
                name: 'Google 3D',
                iconUrl: buildModuleUrl('/map_provider_google_3d.png'),
                tooltip: '3D Tileset',
                category: '3D',
                creationFunction: function() {
                    myStore.fnCallback((state) => {
                        console.log('3d tilesets => on')
                        state.map_3d_tiles = true
                    })
                    return null
                }
            })

            filteredLayers.push(google3dTiles)

            // Change the category of each filtered layer to 'Basemaps'
            filteredLayers.forEach(layer => {
                layer._category = 'Basemaps';
            });

            viewer.baseLayerPicker.viewModel.imageryProviderViewModels = filteredLayers

            // console.log('viewer.baseLayerPicker.viewModel.imageryProviderViewModels', viewer.baseLayerPicker.viewModel.imageryProviderViewModels)
            // console.log('viewer.baseLayerPicker.viewModel', viewer.baseLayerPicker.viewModel)
        }
    }, [viewerRef?.current?.cesiumElement]);


    // const my_date = new Date()
    // const map_date = {
    //     startTime: my_date.toJSON().slice(0, 10) + "T00:00:00Z",
    //     stopTime:  my_date.toJSON().slice(0, 10) + "T23:59:59Z",
    //     currentTime: my_date.toJSON().slice(0, 10) + "T08:00:00Z",
    // }


    const Map3DTiles = useMemo(() => {
        if (! myStore?.map_3d_tiles) {
            // setTerrainProvider(null)
            return () => null
        }

        // setTerrainProvider( createWorldTerrainAsync() )
        
        return () => <Cesium3DTileset
            url={`https://tile.googleapis.com/v1/3dtiles/root.json?key=${config.api_keys.googlemaps}`} 
            showCreditsOnScreen={true}
            />
    }, [myStore.map_3d_tiles])

    const terrainProvider = createWorldTerrainAsync();

    const MapSelectedAreaOverlay = useMemo(() => {
        return () => myStore.map_layer_selected_area
    },[myStore.map_layer_selected_area])

    const MapClock = useMemo(() => {
        // console.log("updating timeline params for map", myStore.map_date)

        if (! myStore.map_date) {
        return null
        }
        
        return <Clock
                startTime={JulianDate.fromIso8601(myStore.map_date.startTime)}
                stopTime={JulianDate.fromIso8601(myStore.map_date.stopTime)}
                currentTime={( 
                    myStore.map_date.currentTime 
                    ? JulianDate.fromIso8601(myStore.map_date.currentTime) 
                    : null
                )}
                clockRange={ClockRange.LOOP_STOP} // loop when we hit the end time
                clockStep={ClockStep.SYSTEM_CLOCK_MULTIPLIER}
                multiplier={300} // how much time to advance each tick
                shouldAnimate={false} // Animation on by default
            />
    },[myStore.map_date])

    const MapRHSInfo = useMemo(() => {

        // console.log('MapRHSInfo',myStore?.map_rhs_info)

        if (!myStore?.map_rhs_info) {
            return null
        }

        return myStore.map_rhs_info
    },[myStore.map_rhs_info])

    const handleViewerReady = useMemo(() => {
        if (viewerRef?.current?.cesiumElement) {
            // console.log("SET STORE ViewerCE", viewerRef?.current?.cesiumElement)
            window.vr = viewerRef?.current?.cesiumElement
            myStore.fnSetKeyValue('viewerCesiumElement', viewerRef.current.cesiumElement)
            myStore.fnSetKeyValue('viewerRef', viewerRef)
        }
    },[viewerRef?.current?.cesiumElement])

    return <Viewer
        ref={viewerRef}
        id='map-viewer'
        creditContainer={props.creditContainer}
        fullscreenButton={false}
        terrainProvider={terrainProvider}
        // animation={myStore.map_show_clock}
        // timeline={myStore.map_show_clock}
        requestRenderMode 
        maximumRenderTimeChange={0.1}
        fullscreenButton={true}
        homeButton={false}
        >
            <Map3DTiles />
            {/* <Cesium3DTileset
                url={`https://tile.googleapis.com/v1/3dtiles/root.json?key=${config.api_keys.googlemaps}`} 
                showCreditsOnScreen={true}/> */}


            {/* <Globe shadows={false} enableLighting /> */}
            
            {/* <ImageryLayer
                imageryProvider={createWorldImageryAsync()}
                brightness={1.5} // Adjust the brightness value as needed
            /> */}

            <Globe />
            <CameraPosition />
            <MapLayers />
            {/* <GeoVillages /> */}
            <MapSelectedAreaOverlay />
            <MapCustomUserLayers />
            <MapEvents viewerRef={viewerRef} /> {/* handle click / touch events */}
            {MapClock}
            {MapRHSInfo}
        </Viewer>
}

export default Map