import React, { useState, useEffect, useRef } from "react"
import { Alert, Button, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap"
import useDataStore from "../helpers/dataStore"
import shp from 'shpjs'
import { filesize } from "filesize"
import getBoundingBox from "../helpers/getBoundingBox"
import ModalUploadXLSX from "./ModalUploadXLSX"
import { CustomDataSource, Entity } from "resium"
import { Cartesian3, Color } from "cesium"
import { current } from "immer"
import ExcelSidebar from "./ExcelSidebar"
import loadCandidateCallback from "../helpers/loadCandidateCallback"
import { FileExcel, Map } from "react-bootstrap-icons"

const extractShapes = async (files) => {
    let result = {
      hasError: false,
      errorMessage: null,
      data: null
    };
  
    const _formatShape = (_data) => {
      return _data.features;
    };
  
    const _parseFile = async (_file) => {
      let _result = {
        hasError: false,
        errorMessage: null,
        data: null
      };
  
      let _data = await _file
        .arrayBuffer()
        .then((_buffer) => shp(_buffer))
        .catch((_err) => {
          console.error(_err);
          _result.hasError = true;
          _result.errorMessage = "IMPORT_UNRECOGNISED_FILE";
          return null;
        });
  
      _result.data = _formatShape(_data);
  
      if (_result.hasError) return _result;
  
      if (!_result.data || _result.data.length < 1) {
        _result.hasError = true;
        _result.errorMessage = "EXTRACT_FILE_EMPTY";
      }

      if (!_result.hasError) {
        _result.geojson = {
                type: "FeatureCollection",
                crs: { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
                features: _result.data,
            }
        _result.bbox = getBoundingBox(_result.geojson)
        delete(_result.data)
      }
  
      return _result;
    };
  
    // read the files
    result.data = await Promise.all(
      Array.prototype.map.call(files, _parseFile)
    ).catch((err) => {
      console.error(err);
      result.hasError = true;
      result.errorMessage = "Extract went wrong";
      return null;
    });
  
    if (result.hasError) return result;
  
    if (!result.data || result.data.length < 1) {
      result.hasError = true;
      result.errorMessage = "IMPORT_SHAPE_EMPTY";
    }
  
    return result;
};


const ModalUpload = () => {
    const myStore = useDataStore()
    const [pastedImg, setPastedImg] = useState()
    const uploadRef = useRef()
    const [files, setFiles] = useState(null)
    const [files_shp, setFilesShp] = useState(null)
    const [files_xlsx, setFilesXlsx] = useState({})
    const [files_xlsx_buffer, setFilesXLSXBuffer] = useState(null)
    
    const paste_listener = async (e) => {
        console.log("PASTE EVENT", e)

        try {
            const clipboardItems = await navigator.clipboard.read()
            console.log(clipboardItems)
            const blobOutput = await clipboardItems[0].getType('image/png')
            const data = URL.createObjectURL(blobOutput)
            setPastedImg({
                src: data
            })
        } catch(e) {
            console.warn("ERR",e);
        }


    }






    const createEntities = (file) => {
        const data = file.data
        console.log('data', data)
        const markerColourField = (file.selections?.markerColour ? file.selections.markerColour : 'markerColour')
    
        return Object.entries(data).map(([key, item], index) => {
            if (! item) {
                console.warn(`no item for ${key} (${index})`, item)
                return
            }
    
            const { latitude, longitude, identifier, siteName, data } = item;
    
            const markerColour = data[data.length-1][markerColourField]
    
            const color = markerColour ? Color.fromCssColorString(markerColour) : Color.BLUE;
        
    
            // Check if latitude and longitude are numeric
            if (isNaN(latitude) || isNaN(longitude)) {
                console.warn(`Skipping entry with non-numeric latitude or longitude: ${key}`, item);
                return null; // Skip this entry
            }
    
            return (
            <Entity
              key={key}
              name={siteName}
              position={Cartesian3.fromDegrees(longitude, latitude)}
              point={{
                pixelSize: 10,
                color: color
              }}
              description={`
                <h3>${siteName}</h3>
                <p>Identifier: ${identifier}</p>
                <p>Latitude: ${latitude}</p>
                <p>Longitude: ${longitude}</p>
              `}
              onClick={(e) => {
                console.log(`CLICKED ${key} (${file.id})`, item)
                console.log("CUSTOM STATE: ", myStore?.custom_state)
                myStore.fnCallback((state) => {
    
    
                    if (! state.custom_state[file.id]) {
                        console.warn(`state.custom_state[${file.id}] is `, state.custom_state[file.id])
                        state.custom_state[file.id]= {
                            selected: {},
                            data: file.data,
                            file: file
                        }
                    }
                    else if (! state.custom_state[file.id]['selected']) {
                        console.warn(`state.custom_state[${file.id}] no selected `, state.custom_state[file.id])
                        state.custom_state[file.id]['selected'] = {}
                    }

                    if (state.custom_state[file.id]['selected'][item.key]) {
                        // deselect
                        console.log("REMOVING",item)
                        delete state.custom_state[file.id]['selected'][item.key]
                    } else {
                        console.log("ADDING",item)
                        state.custom_state[file.id]['selected'][item.key] = item
                    }

    
    
                    console.log("New custom states: ", current(state.custom_state))
                })
              }}
            />
          );
        });
    };






    const handleClose = () => {
        console.log("closing intro")
        myStore.fnSetKeyValue('status_show_modal_upload', false)
        window.removeEventListener('paste', paste_listener);
    }



    useEffect(() => {
        console.log("EFFECT=> myStore.status_show_modal_upload",myStore.status_show_modal_upload)
        if (myStore.status_show_modal_upload) {
            window.addEventListener('paste', paste_listener)
        }

        return () => window.removeEventListener('paste', paste_listener);
    },[myStore.status_show_modal_upload])
    
    useEffect(() => {
        if (!files) {
            return
        }

        console.info("FILES", files)
        window.files = files

        const zip_files = files.filter(file => file.name.toLowerCase().substr(-4) == '.zip')
        if (zip_files.length > 0) {
            console.log(`Processing ${zip_files.length} zip files (shapes?)`, zip_files)
            async function fetchShapes() {
                var new_shp_data = await extractShapes(zip_files);
                console.info('new_shp_data', new_shp_data)
                setFilesShp(new_shp_data)
            }
            fetchShapes()
        }


        const xlsx_files = files.filter(file => file.name.toLowerCase().substr(-5) == '.xlsx')
        if (xlsx_files.length > 0) {
            console.log(`Processing ${xlsx_files.length} xlsx files`, xlsx_files)
            setFilesXLSXBuffer(xlsx_files)
        }

    },[files])

    const addUserLayersToMap = () => {
        myStore.fnCallback((state) => {

            const layers = state.map_custom_layers
            
            files.map((file, fx) => {
                const shp = files_shp?.data[fx]
                const id = `custom_${file.name}`

                const new_layer = {
                    id: id,
                    file: file,
                    type: 'geojson',
                    shp: shp,
                }

                layers[id] = new_layer

                // if no state for this upload, set default custom_state (nb geojson here):
                state.custom_state[file.id] = {
                    selections: {}
                }
                console.log(`creted empty selections obj for file ${fx}`, file)
            })

            state.map_custom_layers = layers
            state.status_show_modal_upload = false

        })
    }


    const addXLSXLayersToMap = () => {
        // const newLayers = {}
        // const newStates = {}
        const newFiles = {}

        Object.keys(files_xlsx).map((filename, fx) => {
            // const shp = files_shp?.data[fx]
            const file = files_xlsx[filename]
            console.log("filexls to add", file)
            const id = file.id

            const entities = createEntities(file);

            console.log(`received ${entities.length} entities`)

            const new_layer = <CustomDataSource key={`file_${file.file.name}_layer`} name={`locations_${filename}`}>
              {entities}
            </CustomDataSource>

            // newLayers[id] = new_layer
            // newStates[id] = { selected: {}, data: file.data, file: file }
            newFiles[id] = {
                layer: new_layer,
                state: { selected: {}, data: file.data, file: file },
                file: file
            }
                        
            console.log(`created empty selections obj for file ${fx}`, file)
        })

        myStore.fnCallback((state) => {

            Object.entries(newFiles).map(([layer_id, file],lx) => {
                console.log(`Adding file '${layer_id}`, file)
                const candidateDataSet = {
                    id: layer_id,
                    title: `${file.layer?.props?.name}`,
                    config: {
                        map_layer: file.layer,
                        left_menu_data_source:  <ExcelSidebar id={layer_id} />,
                        data_fields: file.file.selections,
                        custom_state: file.state,
                        // also opportunity here to ad things like file.selectedSheet, file.file.lastModifiedDate, file.file.name, file.file.size etc..
                    }
                }

                loadCandidateCallback(state, candidateDataSet)
            })


            state.status_show_modal_upload = false

            setFilesXlsx({})
            setFilesXLSXBuffer(null)
            setFiles(null)
        })

    }


    if (files_xlsx_buffer) {
        console.log(`There are ${files_xlsx_buffer.length} xlsx files to process`, files_xlsx_buffer)
        return <ModalUploadXLSX 
            file={files_xlsx_buffer[0]} 
            callback_on_close={(fileData) => {
                console.log('Received', fileData)

                if (fileData.ready) {
                    console.info('modal upload:: file ready', fileData)
                    setFilesXlsx((prev) => ({ ...prev, [fileData.file.name]: fileData }));
                    
                }
                else {
                    console.warn('ModalUpload:: file not ready', fileData)
                }
                const file = files_xlsx_buffer.shift()
                console.log("shifted", file)
                setFilesXLSXBuffer(null)
            }}
        />
    }

    return <Modal 
            show={myStore.status_show_modal_upload} 
            onHide={handleClose} 
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{
                fontSize:".8em"
            }}
        >
        <Modal.Header closeButton>
            <Modal.Title 
                style={{
                    width:'100%',
                    }}>
                <Container>
                    <Row>
                        <Col md={8}>Upload data</Col>
                        <Col md={4} style={{ 
                                    textAlign:'right',
                                }}>
                            {/* <Button variant='secondary'>Login</Button> */}
                            <Button variant='secondary' onClick={() => {
                                myStore.fnSetKeyValue('status_show_modal_data', true)
                                myStore.fnSetKeyValue('status_show_modal_upload',false)
                            }}>Data catalogue</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <Row>
                    <Col>
                        <p>Files are processed in the browser. They won't be saved or uploaded.</p>
                        <Alert variant='info'>
                            <p>Data upload functionality is currently limited to:</p>
                            <p style={{ textIndent: '-1.3em', paddingLeft: '2em'}}>
                                    <FileExcel /> <b>Excel files</b> in .xlsx format, with fields for longitude / latitude / site identifier, value, and optionally colour (e.g. "red")
                                </p>
                            <p style={{ textIndent: '-1.3em', paddingLeft: '2em'}}>
                                    <Map /> <b>Shapefiles</b> for points, lines or polygons, uploaded in a .zip format
                            </p>
                        </Alert>
                        <div>
                            <Button onClick={() => {
                                uploadRef.current.click()
                            }}>Add local data</Button>
                            <Form.Control 
                                type="file" 
                                ref={uploadRef}
                                multiple={true}
                                onChange={(e)=>{
                                    const files = uploadRef.current.files
                                    console.log("FILEs", files)
                                    setFiles(Array.from(files))
                                }} 
                                hidden
                                />
                            {' '}
                            <Button disabled>Add web data /*todo*/</Button>
                        </div>
                    </Col>
                    <Col style={{
                        border: '1px dashed #2EA2F5'
                    }}>
                        {/* You can paste things here. */}
                        { pastedImg?.src && <img src={pastedImg.src} alt='pasted image' width='100%'/>}
                        { files &&
                            files.map((file,fx) => {

                                const minimap = (files_shp && files_shp?.data[fx] && files_shp?.data[fx].bbox
                                    ? <img src={`https://services.arcgisonline.com/arcgis/rest/services/World_Street_Map/MapServer/export?bbox=${
                                        `${files_shp?.data[fx].bbox.minLng},${files_shp?.data[fx].bbox.minLat},${files_shp?.data[fx].bbox.maxLng},${files_shp?.data[fx].bbox.maxLat}`
                                    },41&bboxSR=4326&size=120,120&f=image`} />
                                    : null)
                                
                                return <Container key={`file_${fx}_details`} style={{ padding:'10px' }}>
                                    <Row>
                                        <Col>

                                            <div><strong>{file.name}</strong></div>
                                            <div>Size: {filesize(file.size)}</div>
                                            { files_shp && files_shp?.data[fx] && <div>
                                                {(files_shp.data[fx].hasError
                                                    ? `Error loading shapefile: ${files_shp.data[fx].errorMessage}`
                                                    : `Loaded shp, ${files_shp.data[fx].geojson.features.length} features`
                                                )}
                                                </div>
                                            }
                                            {
                                                files_xlsx[file.name]
                                                &&
                                                files_xlsx[file.name].ready
                                                &&
                                                <Button 
                                                    variant='info'
                                                    style={{marginBottom:'50px'}}
                                                    onClick={() => {
                                                                console.log("ADDING custom xlsx to map")
                                                                addXLSXLayersToMap()
                                                            }}
                                                    >
                                                    Add xlsx to map
                                                </Button>
                                            }

                                        </Col>
                                        <Col md={5}>
                                            {minimap}
                                        </Col>
                                    </Row>
                                </Container>
                                    
                            })
                        }
                        
                        { ((files_shp && ! files_shp?.hasError))&& <Button 
                            variant='info'
                            style={{marginBottom:'50px'}}
                            onClick={() => {
                                        console.log("ADDING custom shps to map")
                                        addUserLayersToMap()
                                    }}
                            >
                            Add to map
                        </Button> }
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            
        </Modal.Footer>
    </Modal>
}

export default ModalUpload