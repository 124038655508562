import { useEffect, useMemo, useState } from "react";
import useDataStore from "../helpers/dataStore";
import config from "../helpers/config";
import { Cartesian3, Color, HeadingPitchRoll, HeightReference, JulianDate, 
    PolylineDashMaterialProperty, PolylineOutlineMaterialProperty, Rectangle, SampledPositionProperty, 
    Transforms, Math as CesiumMath, 
    SampledProperty,
    HermitePolynomialApproximation,
    VelocityOrientationProperty,
    Matrix4,
    ColorMaterialProperty,
    Material,
    Quaternion,
    CallbackProperty,
    } from "cesium";
import { BoxGraphics, CustomDataSource, CylinderGraphics, Entity, Polyline, PolylineCollection, PolylineGraphics, RectangleGraphics } from "resium";

// // Function to create a darker color
// function createDarkerColor(color, factor) {
//     return new Color(
//         color.red * factor,
//         color.green * factor,
//         color.blue * factor,
//         color.alpha * .5
//     );
// }

const MapABMRouting = () => {
    const myStore = useDataStore()
    // const [route_elements, set_route_elements] = useState(null)


    const route_elements = useMemo(() => {
        const routes = myStore.abm_routing

        console.log('routes', routes)
        window.routes = routes

        if (! routes) {
            return null
        }

        const elements = []
        
        // deal with each agent - show a box moving around
        Object.keys(routes.agents).map((agent_id,ix) => {   
            const route = routes.agents[agent_id]

            const agent_colour = Color.fromRandom({minRed:.9, minBlue:.8, minGreen:.7, alpha:1})
            // const dark_colour = createDarkerColor(agent_colour, 0.5)
            
            const sampledPosition = new SampledPositionProperty()
            const degreesArray = []

            route.map((location, lx) => {
                // console.log(`location ${lx}`, location)

                const position = Cartesian3.fromDegrees(location.longitude, location.latitude)
                degreesArray.push(location.longitude)
                degreesArray.push(location.latitude)
                degreesArray.push(2)

                const map_date = myStore.map_date.startTime.substr(0,10)
                
                const arrival_time_str = map_date + `T${location.time}Z`
                const arrival_time = new JulianDate.fromIso8601( arrival_time_str)
                sampledPosition.addSample(arrival_time, position)
            })

            if (routes.show_route_lines) {
                const polylineEntity = <Entity
                        name={`Journey for ${agent_id}`}
                        description={`Agent ${agent_id}'s journey`}
                            key={`journey_agent_${agent_id}_polyline`}
                        position={Cartesian3.fromDegrees(route[0].longitude, route[0].latitude, 100)}
                        clampToGround={true}
                        >
                        <PolylineGraphics
                            positions={Cartesian3.fromDegreesArrayHeights(degreesArray)}
                            width={3}
                            clampToGround={true}
                            material={
                                new PolylineDashMaterialProperty({
                                    color: agent_colour.withAlpha(1),
                                    gapColor: agent_colour.withAlpha(0.3),
                                })
                            } 
                            
                            />
                    </Entity>
                elements.push(polylineEntity)
            }
            
            sampledPosition.setInterpolationOptions({
                interpolationDegree : 3,
                interpolationAlgorithm : HermitePolynomialApproximation
            });

            const orientation = new VelocityOrientationProperty(sampledPosition);


            const translation = new Cartesian3(0,0,10)


            const journeyEntity = <Entity
                    description={`<h3>${agent_id}<h3>`}
                    key={`journey_agent_${agent_id}`}
                    position={sampledPosition}
                    // point={point}
                    clampToGround={true}
                    orientation={orientation}
                    heightReference={HeightReference.CLAMP_TO_GROUND}
                    >
                    <BoxGraphics 
                        material={agent_colour} 
                        dimensions={new Cartesian3(15, 7, 8)} 
                        heightReference={HeightReference.CLAMP_TO_GROUND}
                        clampToGround={true}
                        position={translation} // Adjust the position here
                    />
                </Entity>

            elements.push(journeyEntity)

        })



        // eslint-disable-next-line react/display-name
        return( elements )

    },[myStore.abm_routing])

    return <CustomDataSource 
            key='abm_route_elements'
        >
        {route_elements}
    </CustomDataSource>
}

export default MapABMRouting;