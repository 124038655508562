import { useStore } from "zustand";
import useDataStore from "../helpers/dataStore";
import React, { useMemo } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { current } from "immer";
import { CameraFlyTo } from "resium";
import { Cartesian3 } from "cesium";
import { Crosshair, Map, MarkerTip, Pin, PinMap } from "react-bootstrap-icons";

import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";



const ExcelSidebar = ({id}) => {
    const myStore = useDataStore()

    const getChartData = () => {
        console.log(`Getting chart data for ${id}`, myStore.custom_state[id])

        const datasets = myStore.custom_state[id]['selected']
        const fields = myStore.custom_state[id]['file']['selections']
        console.log("Fields", fields)

        const allDates = new Set();
        const indicators = new Set();
        const sites = new Set();
        const keys = new Set();

        Object.values(datasets).forEach(dataset => {
            sites.add(dataset?.siteName);
            dataset.data.forEach(entry => {
                allDates.add(entry.dtYYYYMMDD);
                indicators.add(entry?.[fields?.indicator])
                keys.add(fields?.indicator ? `${dataset?.siteName} - ${entry?.[fields?.indicator]}` : dataset?.siteName)
            });
        });

        console.log(`${allDates.size} dates`, allDates)
        console.log(`${indicators.size} indicators`, indicators)
        console.log(`${sites.size} sites`, sites)
    
        const mergedData = {};

        allDates.forEach(date => {
            mergedData[date] = { date };
        });

    
        Object.values(datasets).forEach(dataset => {
            dataset.data.forEach(entry => {
                const date = entry.dtYYYYMMDD;
                const key = (fields?.indicator ? `${dataset?.siteName} - ${entry?.[fields?.indicator]}` : dataset?.siteName)
                mergedData[date][key] = entry?.[fields.value];
            });
        });

        if (indicators.size == 0) {
            console.warn("indicators.size=0, adding 'indicator'")
            indicators.add('indicator')
        }

        console.log('mergedData', mergedData)
    
        return {
            data: Object.values(mergedData),
            indicators: indicators,
            sites: sites,
            keys: keys,
        };

    }


    const removeDataset = () => {
        console.log(`Removing ${id}`)
        myStore.fnCallback((state) => {
            console.log("CURRENT STATE", current(state))
            if (state?.custom_layers && state?.custom_layers[id]) {
                delete state.custom_layers[id]
            }
            if (state?.custom_state && state?.custom_state[id]) {
                delete state.custom_state[id]
            }
            if (state?.left_menu_data_sources && state?.left_menu_data_sources[id]) {
                delete state.left_menu_data_sources[id]
            }
            if (state?.selected_datasets) {
                state.selected_datasets = state.selected_datasets.filter(e => e.id != id)
            }


            if (state.map_layers[id]) {
                delete state.map_layers[id]
            }

        })
    }

    const selectedElements = useMemo(() => {

        const data = myStore.custom_state[id]

        const fields = data.file.selections

        if (! data) {
            console.log(`no data `, data)
            return <p className='warning'>No data</p>
        }

        console.log(`data has changed`, data)


        if (! data?.selected) {
            return <p>nothing selected</p>
        }

        console.log("data.selected", data.selected)
        console.log('fields', fields)

        return Object.keys(data.selected).map((key, sx) => {
            const item = data.selected[key]

            const title = ((fields?.title & item[fields.title]) ? item[fields.title] : key)

            return <Row key={`sidebar_${key}_item_sx`}>
                <Col md={10}>
                {item.siteName}
                </Col>
                <Col 
                    md={1}
                    style={{cursor:'pointer'}}
                    onClick={
                    () => {
                        console.log('fly to ', item)


                        const destination = Cartesian3.fromDegrees(
                            item.longitude, 
                            item.latitude,
                            200
                        )

                        myStore.fnCallback((state) => {
                            state.cameraPosition = <CameraFlyTo
                                duration={3} 
                                destination={destination}
                                onComplete={() => {
                                    console.log('finished camera change')
                                    myStore.fnSetKeyValue('cameraPosition', null)
                                }}
                            />
                        })
                    }
                }>
                    <PinMap />
                </Col>
                <Col
                    md={1}
                    style={{cursor:'pointer'}}
                    onClick={
                    () => {
                        console.log(`removing ${key}`, item)
                        myStore.fnCallback((state) => {
                            // myStore.custom_state[id]
                            delete state.custom_state[id]['selected'][key]
                        })
                    }
                }>
                    X
                </Col>
                </Row>


        })
        
    },[myStore.custom_state[id]['selected']])
    

    const amazingTimeseriesChart = useMemo(() => {
        const datasets = myStore.custom_state[id]['selected']
        console.warn(`EXCEL SIDEBAR amazingTimeseriesChart`, datasets)

        if (Object.keys(datasets).length == 0) {
            return null
        }

        const chartData = getChartData()

        console.log("Received datasets", chartData)

        const lines = []
        
        chartData.keys.forEach((key) => {
            console.log(`**${key}**`)

            lines.push(<Line
                key={`${key}`}
                type="monotone"
                dataKey={`${key}`}
                stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`}
                connectNulls={true} // This ensures the line is continuous
                dot={false} 
                strokeWidth={3}
                />
            )
        })
        
        console.log('lines', lines)

        return <ResponsiveContainer width="100%" height={400} style={{marginTop: '1em'}}>

        <LineChart width={600} height={300} data={chartData.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
           {lines}

            {/* {Object.keys(datasets).map((key, index) => (
                <Line
                    key={key}
                    type="monotone"
                    dataKey={datasets[key].siteName}
                    stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`}
                    connectNulls={true} // This ensures the line is continuous
                    dot={false} 
                    strokeWidth={3}
                />
            ))} */}
        </LineChart>
    </ResponsiveContainer>
        

    }, [myStore.custom_state[id]['selected']])

    return <>
        <Container>
            <Row>
                <Col md={8} style={{
                    whiteSpace: 'nowrap', // stop text from wrapping
                    overflow:'hidden', // hide overflowing text
                    textOverflow: 'ellipsis' // add '...' at the point where text is cropped.
                }}>
                    <strong>{id}</strong>
                </Col>
                <Col>
                    <Button variant='warning' onClick={removeDataset}>Remove</Button>
                </Col>
            </Row>
            {selectedElements}
        </Container>
        {
            amazingTimeseriesChart
        }
    </>
}

export default ExcelSidebar