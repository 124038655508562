import { useMemo } from "react";
import useDataStore from "../helpers/dataStore";
import { Button } from "react-bootstrap";

function CensusInfo() {
    const myStore = useDataStore()
    
    const element = useMemo(() => {

        console.info("myStore.census_area_data", myStore.census_area_data)

        if (! myStore.census_area_data) {
            return null
        }

        console.log('myStore.selected_area',myStore.selected_area)

        const funFacts = []
        var previous_table_name = ""

        myStore.census_area_data.map((funFact) => {

            if (funFact.table_name != previous_table_name) {
                previous_table_name = funFact.table_name
                funFacts.push(<div>
                    <span style={{fontWeight:'bold'}}>{funFact.table_name}</span>
                </div>)
            }

            funFacts.push(<div style={{display:'inline-flex', borderBottom:'1px solid #000000'}}>
                <div style={{color:'silver', width: '250px', textAlign:'right', display:'inline-block'}}>{funFact.variable}</div>
                <div style={{fontWeight:'bold', marginLeft:'10px', width: '50px', color:'yellow',  display:'inline-block'}}>{funFact.value}</div>            
            </div>)
        })


        return <div style={{
                    padding: '0 .5em',
                    fontSize: '.8em',
                    // overflow: 'auto',
                }}>
                    <h1>{myStore.selected_area.admin_area.name}</h1>
                    <Button variant='secondary' onClick={() => {
                        myStore.fnSetKeyValue('census_area_data',null)
                    }}>Close</Button>
                    <p>{myStore.census_area_data.length} fun facts:</p>
                    {funFacts}
                </div>

    },[myStore.census_area_data])

    return element
}

export default CensusInfo;