import { lazy, Suspense } from "react";
import { Spinner } from "react-bootstrap";

const componentMap = {
    // chatbot
    'Chatbot': () => 
        import('../chatbot.jsx'),

    // classic digital twin components
    'ClassicDTMenu': () => 
        import('./ClassicDT/ClassicDTMenu.jsx'),
    'ClassicDTMap': () => 
        import('./ClassicDT/ClassicDTMap.jsx'),
    'ClassicMapKey': () => 
        import('./ClassicDT/ClassicMapKey.jsx'),
    'ClassicNetwork': () => 
        import('./ClassicDT/ClassicNetwork.jsx'),
    // sa2 popn estimates
    'LeftMenuMapSA2PopulationEstimates': () => 
        import('./LeftMenuMapSA2PopulationEstimates.jsx'),

    // Outbreak modelling
    // import LeftMenuOutbreak from "./LeftMenuOutbreak"
    // import MapOutbreak from "./MapOutbreak"
    // import MapOutbreakSA2 from "./MapOutbreakSA2"
    'LeftMenuOutbreak': () => 
        import('./LeftMenuOutbreak.jsx'),
    'MapOutbreak': () => 
        import('./MapOutbreak.jsx'),
    'MapOutbreakSA2': () => 
        import('./MapOutbreakSA2.jsx'),

    // sumo - traffic modelling
    'MapSumo': () => 
        import('./MapSumo.jsx'),

    // metlink
    'MapMetlink': () => 
        import('./MapMetlink.jsx'),
    'MapSA2PopulationEstimates': () => 
        import('./MapSA2PopulationEstimates.jsx'),

    'TermsOfUse': () => 
        import('../TermsOfUse.jsx'),
    'Licence': () => 
        import('../Licence.jsx'),
    'Login': () => 
        import('../Login.jsx'),
    // Add other components here
}

const LazyComponent = (props) => {
    console.log(`LazyComponent: ${props.component}`)

    const lazyFile = componentMap?.[props.component]

    if (! lazyFile) {
        console.warn(`COMPONENT ${props.component} not fount in componentMap`)
        return null
    }

    const MyLazyComponent = lazy(lazyFile)

    return <Suspense fallback={<div><Spinner /> Please wait..</div>}>
        <MyLazyComponent {...props} />
    </Suspense>
}

export default LazyComponent;