import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import useDataStore from "../helpers/dataStore"
import { CameraFlyTo } from "resium"
import { Rectangle } from "cesium"
import { useMemo, useState } from "react"
import { CheckCircleFill, SquareFill, XCircleFill } from "react-bootstrap-icons"
import { ChromePicker } from "react-color"

const defaultColor = '#FF0000'
const defaultWidth = 3

const ColorPickerComponent = ({ currentColor, onChangeComplete }) => {
    return (
        <ChromePicker
            color={currentColor}
            onChangeComplete={onChangeComplete}
        />
    );
};

const LeftMenuCustomSHP = ({layer_id}) => {
    const myStore = useDataStore()
    const [showConfigure, setShowConfigure] = useState(false)
    const [fields, setFields] = useState(null)
    const [selectedField, setSelectedField] = useState(null)
    const [selectedFieldValues, setSelectedFieldValues] = useState(null)
    const [customStyles, setCustomStyles] = useState({
        default: {
            color: defaultColor,
            width: defaultWidth,
        },
        fields: {}
    })
    const [showPicker, setShowPicker] = useState(false);

    const handleSquareClick = (value) => {
        if (showPicker == value) {
            setShowPicker(false)
        }
        else{
            setShowPicker(value);
        }
        console.log(`showPicker=${showPicker}`)
    };

    const toggleShowConfigure = () => {
        setShowConfigure(! showConfigure)
    }

    const handleChangeComplete = (color, fieldName) => {
        // setCurrentColor(color.hex);
        console.log(`setting color for ${selectedField}.${fieldName}=${color}`)
        const newStyles = { ...customStyles };
        newStyles.fields = newStyles.fields || {};
        newStyles.fields[selectedField] = newStyles.fields[selectedField] || {};
        newStyles.fields[selectedField][fieldName] = newStyles.fields[selectedField][fieldName] || {};
        newStyles.fields[selectedField][fieldName].color = color.hex;
        setCustomStyles(newStyles);
    };

    const handleSizeChange = (size, fieldName) => {
        console.log(`size change ${selectedField}.${fieldName} = ${size}`)
        const newStyles = { ...customStyles };
        newStyles.fields = newStyles.fields || {};
        newStyles.fields[selectedField] = newStyles.fields[selectedField] || {};
        newStyles.fields[selectedField][fieldName] = newStyles.fields[selectedField][fieldName] || {};
        newStyles.fields[selectedField][fieldName].width = size;
        setCustomStyles(newStyles);
    }

    const layer_info = useMemo(() => {
        return myStore.map_custom_layers[layer_id]
    },[])


    console.log('layer_info', layer_info)

    useMemo(() => {
        if (! layer_info) {
            return
        }

        const features = layer_info.shp.geojson.features

        // populate fields with properties of first geometry.
        const field_keys = (Object.keys(features[0].properties))
        const new_fields = {}
        
        field_keys.map((field, fx) => {
            new_fields[field] = {
                i: fx,
                field: field,
                values: null
            }
        })

        console.log("new fields", new_fields)
        setFields(new_fields)

        if (layer_info?.style) {
            console.log("SETTING CUSTOM STYPES FROM layer_info", layer_info.style)
            setCustomStyles(JSON.parse(JSON.stringify(layer_info.style)))
        }

    }, [layer_info])

    const updateMapConfig = () => {
        console.log("Updating map", myStore.map_custom_layers[layer_id])
        console.log("new styles:", customStyles)

        myStore.fnCallback((state) => {
            const new_layer = { ...state.map_custom_layers[layer_id] }
            new_layer.style = customStyles
            state.map_custom_layers[layer_id] = new_layer
        })
    }

    // memo to show options from currently selected fields.
    const selectedFieldUI = useMemo(() => {
        console.log('selectedFieldUI', customStyles)

        if (! selectedFieldValues) {
            return null
        }

        const valueRows = Object.entries(selectedFieldValues).map(([fieldName, count], ix) => {
            const currentStyle = customStyles['fields']?.[selectedField]?.[fieldName]
            // console.log(`adding ${fieldName}`, currentStyle)

            const currentColor = (currentStyle?.color ? currentStyle?.color : defaultColor)
            const currentWidth   = (currentStyle?.width ? currentStyle?.width  : defaultWidth)

            const showVariable = ! (customStyles['fields']?.[selectedField]?.[fieldName]?.show == false)

            return [
            <tr key={`selected_field_value_${ix}`}>
                <td onClick={() => {
                    const newStyles = {...customStyles}

                    if (currentStyle?.show == false) {
                        console.log('was false, deleting')
                        delete newStyles['fields'][selectedField][fieldName]?.show
                    } else {
                        console.log('setting show=false')
                        newStyles.fields[selectedField] = newStyles.fields[selectedField] || {};
                        newStyles.fields[selectedField][fieldName] = newStyles.fields[selectedField][fieldName] || {};
                        newStyles['fields'][selectedField][fieldName].show = false
                    }

                    setCustomStyles(newStyles)
                }}>
                    {
                        (
                            showVariable
                            ? <CheckCircleFill style={{color:'forestgreen'}} />
                            : <XCircleFill style={{color:'tomato'}} />
                        )
                    }
                </td>
                <td style={{ 
                    textDecoration: showVariable ? 'none': 'line-through'
                    }}>{fieldName}</td>
                <td>{count}</td>
                {
                    showVariable
                    &&
                    <td  onClick={ () => handleSquareClick(fieldName) }>
                        <SquareFill style={{color:currentColor}} />

                        <div style={{
                            borderTop: `${currentWidth}px solid ${currentColor}`,
                            width: '2em',
                            height: '${currentWidth}px',
                            display: 'inline-block',
                            marginLeft: '.5em'
                        }} />
                    </td>
                    ||
                    <td></td>
                }
                
            </tr>
            ,
            ( 
                showVariable
                &&
                showPicker===fieldName 
                && 
                <tr>
                    <td colSpan='3'>
                        <ColorPickerComponent 
                            currentColor={currentColor} 
                            onChangeComplete={(hex) => handleChangeComplete(hex, fieldName)} />
                        
                    </td>
                    <td>
                        <Form.Group controlId="sizePicker">
                            <Form.Label>Line</Form.Label>
                            <Form.Select
                                aria-label='Line width'
                                onChange={(e) => handleSizeChange(e.target.value, fieldName)}
                                value={currentWidth}
                            >
                            {
                                [...Array(10).keys()].map(num => (
                                    <option key={`linewidth_${num+1}`} value={num+1}>
                                        {num+1}
                                    </option>
                                ))
                            }
                            </Form.Select>
                        </Form.Group>
                        
                        <Button variant='warning' style={{marginTop: '1em'}} onClick={() => {
                            setShowPicker(false)
                        }}>Close</Button>
                        
                    </td>
                </tr>)
            ]
        })

        return <Table striped bordered hover size='sm' style={{marginTop:'1em'}}>
            <thead>
                <tr>
                    <th></th>
                    <th>Variable</th>
                    <th>#</th>
                    <th>Style</th>
                </tr>
            </thead>
            <tbody>
                { valueRows  }
            </tbody>
        </Table>

    }, [selectedField, selectedFieldValues, customStyles, showPicker])

    // memo to update selectedFieldValues when selectedField changes
    useMemo(() => {
        if (! selectedField) {
            console.warn('no selectedfield - returning')
            setSelectedFieldValues(null)
            return
        }

        const features = layer_info.shp.geojson.features

        const value_counts = features.reduce((acc, feature) => {
            const value = feature.properties[selectedField];
            acc[value] = (acc[value] || 0) + 1;
            return acc;
        }, {});

        const sorted_counts = Object.entries(value_counts)
            .sort(([, a], [, b]) => b - a)
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});


        console.log('sorted_counts counts:', sorted_counts)
        
        setSelectedFieldValues(sorted_counts)

    }, [selectedField])

    // memo to select a different field.
    const selectField = (e) => {
        console.log("select field", e.target.value)
        setSelectedField(e.target.value)
    }

    const configureLayer = useMemo(() => {
        if (! showConfigure) {
            return null
        }

        return <Form>
            <Card style={{marginTop: '1em'}}>
            <Card.Body>
                <Card.Title style={{
                    whiteSpace: 'nowrap', // stop text from wrapping
                    overflow:'hidden', // hide overflowing text
                    textOverflow: 'ellipsis' // add '...' at the point where text is cropped.
                }}>
                    Configure {layer_id}
                </Card.Title>
                <Card.Text>
                   
                    <Form.Select aria-label='Select field' onChange={selectField}>
                        <option value=''>Select a field</option>
                        {
                            fields
                            
                            &&

                            Object.entries(fields).map(([key, field], fx) => {
                                // console.log(`Field ${key}`, field)
                                return <option value={key}>{field.field}</option>
                            })
                        }
                    </Form.Select>

                    {selectedFieldUI}

                </Card.Text>
                <Button onClick={() => {
                    toggleShowConfigure()
                    updateMapConfig()
                }}>Update</Button>
            </Card.Body>
        </Card>
        </Form>
    }, [showConfigure, selectedFieldUI])


    return <Container key={`user_map_layer_left_${layer_id}`}>
        <Row>
            <Col>
                <strong>{layer_info.file.name}</strong>
                {' '}
                {`${layer_info.shp.geojson.features.length} features`}            
            </Col>
        </Row>
        
        <Row>
            <Col>
                <Button variant='warning' onClick={() => {
                    myStore.fnCallback((state) => {
                        console.log(`removing ${layer_id}`)
                        const layers = Object.assign({}, state.map_custom_layers)
                        delete layers[layer_id]
                        state.map_custom_layers = layers
                    })
                    }}>Remove</Button>
                {' '}
                <Button onClick={() => {
                    console.log('flying to ', layer_info)
                    const bounds = layer_info.shp.bbox
                    
                    const lngPadding = (bounds.maxLng-bounds.minLng)*.1
                    const latPadding = (bounds.maxLat - bounds.minLat) * .1
                    const destination = Rectangle.fromDegrees(
                        bounds.minLng-lngPadding, 
                        bounds.minLat-latPadding, 
                        bounds.maxLng+lngPadding, 
                        bounds.maxLat+latPadding)

                    

                    const newCameraPosition = <CameraFlyTo
                        duration={3} 
                        destination={destination}
                        // orientation={myStore.selected_twin.default_camera_orientation} 
                        onComplete={() => {
                            console.log('finished camera change')
                            myStore.fnSetKeyValue('cameraPosition', null)
                        }} />

                    myStore.fnCallback((state) => {
                        state.cameraPosition = newCameraPosition
                    })
                }}>Fly to</Button>
                {' '}
                <Button variant='secondary' onClick={toggleShowConfigure}>Configure</Button>
            </Col>
        </Row>
        {configureLayer}
    </Container>
}


export default LeftMenuCustomSHP;