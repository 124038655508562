import Joyride, { ACTIONS, STATUS } from "react-joyride"
import useDataStore from "../helpers/dataStore";
import React, { useMemo, useState } from "react";

const Tour = () => {
    const myStore = useDataStore()

    const handleJoyrideCallback = (data) => {
        const { action, status, type } = data;
      
        if (action === ACTIONS.CLOSE || [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
          console.log('Joyride closed');
          // Add your custom logic here
          myStore.fnCallback((state) => {
            state.tour.shown = false
          })
        }
      
        console.groupCollapsed(type);
        console.log(data); // For debugging purposes
        console.groupEnd();
      };

    const TourElement = useMemo(() => {
        if (!myStore.tour.shown) {
            return null
        }

        if (! myStore?.tour?.steps) {
            console.log("tour -- no steps")
            return null
        }

        console.log("tour -- steps :)", myStore.tour.steps)
        return <Joyride 
            steps={myStore.tour.steps} 
            callback={handleJoyrideCallback} 
            run={true}
            continuous={true}
            showProgress={true}
            showSkipButton={true}
            locale={{
                last: 'Finish',
            }}
            styles={{
                options: {
                    arrowColor: '#0d6efd',
                    backgroundColor: '#FFFFFF',
                    primaryColor: '#0d6efd',
                    // textColor: '#000',
                    beaconSize: 36,
                    overlayColor: 'rgba(0, 0, 0, 0.55)',
                    spotlightShadow: '0 0 15px rgba(50, 0, 0, 0.5)',
                    textColor: '#333',
                    // width: undefined,
                    // maxWidth: '300px',
                    zIndex: 950,
                },
            }}
            />

    }, [myStore.tour.shown, myStore?.tour?.steps])

    return TourElement
}

export default Tour;