import axios from "axios";
import React, { useMemo, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, FormSelect, Row } from "react-bootstrap";
import useDataStore from "../helpers/dataStore";
import { CameraFlyTo } from "resium";
import { Rectangle } from "cesium";
import config from "../helpers/config";

const LeftMenuABMRouting = () => {
    const [zoomToRouteBounds, setZoomToRouteBounds] = useState(false)
    const [showRouteLines, setShowRouteLines] = useState(true)
    const myStore = useDataStore()
    const numAgentsRef = useRef()

    const loadAgentsFromURL = (url) => {
        console.log(`loading agents from ${url}`)
        axios
            .get(url, myStore.api_axios_header)
            .then((response) => {
                console.log("Received", response.data)

                myStore.fnCallback((state) => {
                    
                    state.abm_routing = response.data
                    state.abm_routing.show_route_lines = showRouteLines

                    state.map_show_clock = true

                    state.map_date = {
                        // 'date': state.map_date.date,
                        'startTime': state.map_date.startTime.slice(0, 10) + "T08:00:00Z",
                        'stopTime':  state.map_date.startTime.slice(0, 10) + "T09:30:00Z",
                        'currentTime': state.map_date.startTime.slice(0, 10) + "T08:30:00Z",
                    }

                    const position = Rectangle.fromDegrees(
                        response.data.bounds.minLng,
                        response.data.bounds.minLat,
                        response.data.bounds.maxLng,
                        response.data.bounds.maxLat
                    )

                    console.log("flying to" , position)

                    if (zoomToRouteBounds) {
                        state.cameraPosition = <CameraFlyTo
                            duration={3} 
                            destination={position} 
                            // orientation={myStore.selected_twin.default_camera_orientation} 
                            onComplete={() => {
                                console.log('finished camera change')
                                myStore.fnSetKeyValue('cameraPosition', null)
                            }}
                        />
                    }
                    
                })
                
            })
            .catch((err) => {
                console.warn("ERROR", err)
            })
    }


    const loadNAgents = () => {
        console.log("Loading n agents")
        const n = numAgentsRef.current.value

        console.log(`n=${n}`)
        
        const bounds = myStore.map_viewport.bounds
        const url = `${config.dev_api_url}/routes_by_bounds?minLng=${bounds.west}&maxLng=${bounds.east}&minLat=${bounds.south}&maxLat=${bounds.north}&limit=${n}`

        console.log("viewport:", myStore.map_viewport)

        
        console.log('loading', url)
        loadAgentsFromURL(url)
    }

    // value = csv of agent ids, or else 'b<nn>'
    const getAgentRoutes = (value) => {
        // load route info for this agent id:
        var url = `${config.dev_api_url}/routes_by_agent/${value}`

        // get all agents in bounds.
        
        const match = value.match(/^b(\d+)?$/);
        if (match) {
            const bounds = myStore.map_viewport.bounds

            let limit = 20; // Default limit

            if (match && match[1]) {
                limit = parseInt(match[1], 10);
            }

            url = `${config.dev_api_url}/routes_by_bounds?minLng=${bounds.west}&maxLng=${bounds.east}&minLat=${bounds.south}&maxLat=${bounds.north}&limit=${limit}`
        }
        console.log('loading', url)

        loadAgentsFromURL(url)
    }

    return <div key='left_menu_abm_routing' style={{marginTop: '10px'}}>
            <Container>
                <Row>
                    <Col>
                        <p style={{fontSize:'1.3em', color: '#666'}}>
                            ABM Routing
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card body>

                            Show     
                            {' '}               
                            <FormSelect style={{width: '5em', display:'inline'}} ref={numAgentsRef}>
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                                <option>500</option>
                            </FormSelect>
                            {' '}
                            agents
                            {' '}
                            <Button onClick={ loadNAgents }>
                                Load
                            </Button>
                            {' '}
                            <Button variant='warning' onClick={() => {
                                myStore.fnCallback((state) => {
                                    state.abm_routing = null
                                })
                            }}>Clear</Button>
                        <hr />
                        <Form.Group>
                            <Form.Label>Search by Agent ID: </Form.Label>
                            {' '}
                            <Form.Control 
                                type='text' 
                                placeholder='55113' 
                                style={{ width:'6em', display: 'inline' }}
                                onKeyUp={(e) => {
                                    if (e.key == 'Enter') {
                                        getAgentRoutes(e.target.value)
                                    }
                            }}></Form.Control>
                        </Form.Group>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Check 
                            type='switch'
                            id='reset_bounds_toggle' 
                            label='Zoom to route bounds' 
                            checked={zoomToRouteBounds}
                            onChange={() => {
                                
                                const newState = ! zoomToRouteBounds
                                console.log("3d tile setting toggled", newState)
                                setZoomToRouteBounds(newState)

                                if (newState) {

                                    console.log(myStore.abm_routing)
                                    const position = Rectangle.fromDegrees(
                                        myStore.abm_routing.bounds.minLng,
                                        myStore.abm_routing.bounds.minLat,
                                        myStore.abm_routing.bounds.maxLng,
                                        myStore.abm_routing.bounds.maxLat
                                    )


                                    myStore.fnCallback((state) => {
                                        state.cameraPosition = <CameraFlyTo
                                            duration={3} 
                                            destination={position} 
                                            // orientation={myStore.selected_twin.default_camera_orientation} 
                                            onComplete={() => {
                                                console.log('finished camera change')
                                                myStore.fnSetKeyValue('cameraPosition', null)
                                            }}
                                        />
                                    })
                                }


                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Check
                            type='switch'
                            id='show_routes'
                            label='Show route lines'
                            checked={showRouteLines}
                            onChange={(e) => {
                                const newState = ! showRouteLines

                                setShowRouteLines(newState)

                                if (myStore?.abm_routing?.show_route_lines != newState) {
                                    myStore.fnCallback((state) => {
                                        console.log("updating store show_route_lines",newState)
                                        state.abm_routing.show_route_lines = newState
                                    })
                                }
                            }}
                            />
                    </Col>
                </Row>
            </Container>
        </div>
}

export default LeftMenuABMRouting;