import { Color } from "cesium"
import { GeoJsonDataSource } from "resium"
import useDataStore from "../helpers/dataStore"
import { useParams } from "react-router-dom"
import config from "../helpers/config"

const sources = {
    'tonga_islands': {
        api_query: 'geojson/tonga_islands',
        defaultFill: Color.RED.withAlpha(0.01),
        defaultStroke: Color.RED,
        tooltip: (area_code, entity) => {
            console.log(`TOOLTIP OVER ${area_code}`, entity)
            return <div>
                {/* <div>Village: <strong>{entity.id._properties.ADM3_NAME._value}</strong></div> */}
                {/* <div>District: {entity.id._properties.ADM2_NAME._value}</div> */}
                <div>Island: {entity.id._properties._ADM0_TO._value} {entity.id._properties._ADM1_TO._value}</div>
            </div>
        }
    },
    'tonga_districts': {
        api_query: 'geojson/tonga_districts',
        defaultFill: Color.ORANGE.withAlpha(0.01),
        defaultStroke: Color.ORANGE,
        tooltip: (area_code, entity) => {
            console.log(`TOOLTIP OVER ${area_code}`, entity)
            return <div>
                {/* <div>Village: <strong>{entity.id._properties.ADM3_NAME._value}</strong></div> */}
                <div>District: {entity.id._properties.ADM2_NAME._value}</div>
                <div>Island: {entity.id._properties.ADM1_NAME._value}</div>
            </div>
            }
    },
    'tonga_villages': {
        api_query: 'geojson/tonga_villages',
        defaultFill: Color.HOTPINK.withAlpha(0.01),
        defaultStroke: Color.HOTPINK,
        tooltip: (area_code, entity) => <div>
                <div>Village: <strong>{entity.id._properties.ADM3_NAME._value}</strong></div>
                <div>District: {entity.id._properties.ADM2_NAME._value}</div>
                <div>Island: {entity.id._properties.ADM1_NAME._value}</div>
            </div>
    }
}

// eslint-disable-next-line react/prop-types
const MapGeoJSON = ({source_key}) => {

    const myStore = useDataStore()

    if (! source_key) {
        console.warn(`missing source_key`, source_key)
        return
    }

    console.log("MGJ", source_key)

    const source = sources[source_key]

    if (! source) {
        console.warn(`Unable to find "${source_key}" in sources`, sources)
        return ()=>null
    }

    return <GeoJsonDataSource 
        key={`gjs_${source_key}`}
        data={`${config.dev_api_url}/${source.api_query}`} 
        onMouseEnter={(movement, entity) => {

            const area_code = entity.id._properties.area_code._value
            console.log(`mouse over ${area_code}`)
            

            entity.id.polygon.material = Color.BLUE.withAlpha(0.4)
            entity.id.polygon.outlineColor = Color.YELLOW

            // show tooltip?
            myStore.fnCallback((state) => {
                state.tooltip = {
                    key: area_code,
                    message: source.tooltip(area_code, entity),
                    x: state['map_margin_left'] + movement.endPosition.x,
                    y: movement.endPosition.y
                }
            })

            
        }}
        onMouseLeave={(movement, entity) => {
            // const area_code = entity.id._properties.area_code._value
            // const preMouseoverStyle = preMouseoverStyles[area_code]
            
            entity.id.polygon.material = source.defaultFill
            entity.id.polygon.outlineColor = source.defaultStroke

            myStore.fnSetKeyValue('tooltip', null)
        }}
        onLoad={(gds) => {
            console.log("GDS loaded", gds)
        }}
        fill={source.defaultFill}
        stroke={source.defaultStroke}            
    />
}

export default MapGeoJSON;