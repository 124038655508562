import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, ListGroup, Modal, Row, Spinner, Table } from "react-bootstrap";
import * as XLSX from 'xlsx'
import useDataStore from "../helpers/dataStore";

const preselectColumns = (columns) => {
    const selections = {
      longitude: '',
      latitude: '',
      dateTime: '',
      markerColour: '',
      identifier: '',
      title: ''
    };
  
    columns.forEach((col) => {
      const lowerCol = col.toLowerCase();
      if (!selections.latitude && (lowerCol.includes('latitude') || lowerCol.includes('lat'))) {
        selections.latitude = col;
      }
      if (!selections.longitude && (lowerCol.includes('longitude') || lowerCol.includes('lng') || lowerCol.includes('long') || lowerCol.includes('lon'))) {
        selections.longitude = col;
      }
      if (!selections.dateTime && lowerCol.includes('date')) {
        selections.dateTime = col;
      }
      if (!selections.identifier && lowerCol.includes(' id')) {
        selections.identifier = col;
      }
      if (!selections.title && lowerCol.includes(' name')) {
        selections.title = col;
      }
      if (!selections.value && (lowerCol.includes('val') || lowerCol.includes('concentration'))) {
        selections.value = col;
      }
      if (!selections.indicator && (lowerCol.includes('indicator') || lowerCol.includes('property'))) {
        selections.indicator = col;
      }
    });
  
    return selections;
  };

const createJsonObject = (sheetData, selections) => {
    const { longitude, latitude, dateTime, identifier, title } = selections;
  
    // Find the indices of the selected columns
    const colIndices = {
      longitude: sheetData[0].indexOf(longitude),
      latitude: sheetData[0].indexOf(latitude),
      dateTime: sheetData[0].indexOf(dateTime),
      identifier: sheetData[0].indexOf(identifier),
      title: sheetData[0].indexOf(title)
    };
  
    // Parse date and sort rows by date
    const rows = sheetData.slice(1).map((row) => {
      const dateValue = row[colIndices.dateTime];
      const date = XLSX.SSF.parse_date_code(dateValue);
      return {
        ...row,
        date: new Date(date.y, date.m - 1, date.d)
      };
    }).sort((a, b) => a.date - b.date);
  
    // Create JSON object
    const jsonObject = {}
    
    rows.map((row) => {
      const key = `${row[colIndices.identifier]}_${row[colIndices.longitude]}_${row[colIndices.latitude]}`;
      const data = {};
  
      // Add other columns to data object
      sheetData[0].forEach((col, index) => {
        if (index !== colIndices.longitude 
          && index !== colIndices.latitude 
          // && index !== colIndices.dateTime 
          && index !== colIndices.identifier 
          && index !== colIndices.title) {
          data[col] = row[index];
        }

        const dtDateTime = XLSX.SSF.parse_date_code( row[colIndices.dateTime] )

        // Create a new Date object using the year, month, and day
        data['dtDate'] = new Date(dtDateTime.y, dtDateTime.m - 1, dtDateTime.d, dtDateTime.M, dtDateTime.M, dtDateTime.S)
        data['dtYYYYMMDD'] = data['dtDate'].toISOString().split('T')[0];
      });

      if (! jsonObject[key]) {
        // first time seen - create
        jsonObject[key] = {
            key,
            latitude: Number(row[colIndices.latitude]),
            longitude: Number(row[colIndices.longitude]),
            identifier: row[colIndices.identifier],
            siteName: row[colIndices.title],
            // markerColour: row[colIndices.markerColour],
            data: [data]
          };
      } else {
        // append to existing
        jsonObject[key]['data'].push(data)
        // jsonObject[key]['markerColour'] = row[colIndices.markerColour]
      }

      
    });
  
    return jsonObject;
};

const ModalUploadXLSX = ({file, callback_on_close}) => {
    console.log("MODAL UPLOAD XLSX", file)
    const myStore = useDataStore()
    const [ready, setReady] = useState(false)
    const [okay, setOkay] = useState(false)
    const [sheets, setSheets] = useState([])
    const [workbook, setWorkbook] = useState(null)
    const [selectedSheet, setSelectedSheet] = useState(null)
    const [sheetData, setSheetData] = useState([])
    const [columns, setColumns] = useState([]);
    const [id, setId] = useState('new_file')
    
    const [selections, setSelections] = useState({
        longitude: '',
        latitude: '',
        dateTime: '',
        markerColour: '',
        identifier: '',
        title: '',
        indicator: '',
        value: ''
    });

    const handleFile = (file) => {
        console.log("HANDLING FILE", file)
        setId(`xlsx_file_${file.name}`)
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const new_workbook = XLSX.read(data, { type: 'array' });
          console.log("LOADED WORKBOOK", new_workbook)
          setWorkbook(new_workbook);
          setSheets(new_workbook.SheetNames);
          setReady(true)
        };
        reader.readAsArrayBuffer(file);
      };

    const handleSheetSelect = (sheetName) => {
        console.log(`handling sheet`, sheetName)
        // const workbook = XLSX.read(file, { type: 'array' });
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setSelectedSheet(sheetName);
        setSheetData(jsonData);
        setColumns(jsonData[0]);
    };

    const handleSelectionChange = (e) => {
      const { name, value } = e.target;
      setSelections((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        console.log("SELECTIONS CHANGED", selections)

        setOkay(
            selections.longitude != ''
            &&
            selections.latitude != ''
        )
    }, [selections])

    
    useEffect(() => {
        setSelections(preselectColumns(columns));
    }, [columns]);

    const remove = () => {
      console.log("Removing", file)

      myStore.fnCallback((state) => {
        if (state.left_menu_data_sources[file.id]) {
          delete state.left_menu_data_sources[file.id]
        }

        if (state.map_layers[file.id]) {
          delete state.map_layers[file.id]
        }

        if (state.custom_state[file.id]) {
          delete state.custom_state[file.id]
        }
      })

    }

    // const getSidebar = () => {
    //   console.log(`Getting sidebar for ${file.name}`, file)
    //   return <Container key={`sidebar_for_${file.name}`}>
    //     <Row>
    //       <Col md={9}>
    //         <strong>{file.name}</strong>
    //       </Col>
    //       <Col>
    //         <Button variant='secondary' onClick={ remove }>Remove</Button>
    //       </Col>
    //     </Row>
    //     <Row>
    //       <Col>
    //         {myStore.custom_layers[file.id].selections.map((selected, sx) => {
    //           console.log(`selection ${sx}`, selected)
    //           return <>{sx}. </>
    //         })}
    //       </Col>
    //     </Row>
    //   </Container>
    // }

    useEffect(() => {
        if (file) {
            handleFile(file);
        }
    }, [file]);

    return <Modal 
        show={true} 
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
            fontSize:".8em"
        }}
        onHide={() => {
            if (
              confirm("Are you sure you want to cancel this file upload?")
            ) {
              console.log("closing from x")
              callback_on_close({ready:false})
            }
          }
        }
    >
        <Modal.Header closeButton>
            <strong>Upload {file.name}</strong>
        </Modal.Header>
        <Modal.Body>
            { (! ready) && <><Alert variant='info'><Spinner style={{width:'1.5em', height:'1.5em'}} /> Please wait... Loading file</Alert></> }
            { ready && 
            <Container>
                <Row>
                    <Col>
                        <ListGroup horizontal>
                        {sheets.map((sheet, index) => (
                            <ListGroup.Item 
                                action
                                key={index} 
                                onClick={() => handleSheetSelect(sheet)}>
                            {sheet}
                            </ListGroup.Item>
                        ))}
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col style={{
                        overflowX: 'auto',
                        maxHeight: '400px',
                        overflowY: 'auto'
                    }}>
                        {selectedSheet && (
                        <>
                            <h5>{selectedSheet}</h5>
                            <Table striped bordered hover>
                            <thead>
                                <tr>
                                {sheetData[0]?.map((col, index) => (
                                    <th key={index}>{col}</th>
                                ))}
                                </tr>
                            </thead>
                            <tbody>
                                {sheetData.slice(1, 21).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                    <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                                ))}
                            </tbody>
                            </Table>
                            { (sheetData.length > 21) && <p>{sheetData.length - 21} further rows</p> }
                        </>
                        )}
                    </Col>
                </Row>
            </Container>
            }
        </Modal.Body>
        <Modal.Footer>
            {
                (columns.length>0)
                &&
                <Form style={{width: '100%'}}>
                <Container>
                  <Row>
                    <Col>
                      <Form.Group controlId="longitude">
                        <Form.Label>Longitude</Form.Label>
                        <Form.Control as="select" name="longitude" value={selections.longitude} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="latitude">
                        <Form.Label>Latitude</Form.Label>
                        <Form.Control as="select" name="latitude" value={selections.latitude} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="dateTime">
                        <Form.Label>Date/Time</Form.Label>
                        <Form.Control as="select" name="dateTime" value={selections.dateTime} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="markerColour">
                        <Form.Label>Marker Colour</Form.Label>
                        <Form.Control as="select" name="markerColour" value={selections.markerColour} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="identifier">
                        <Form.Label>Identifier</Form.Label>
                        <Form.Control as="select" name="identifier" value={selections.identifier} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control as="select" name="title" value={selections.title} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="Indicator">
                        <Form.Label>Indicator</Form.Label>
                        <Form.Control as="select" name="indicator" value={selections.indicator} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="value">
                        <Form.Label>Value</Form.Label>
                        <Form.Control as="select" name="value" value={selections.value} onChange={handleSelectionChange}>
                          <option value="">Select Column</option>
                          {columns.map((col, index) => (
                            <option key={index} value={col}>{col}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                    {
                        okay 
                        ?
                        <Button 
                        variant="primary"
                        onClick={() => callback_on_close({
                            file: file,
                            ready: true,
                            selectedSheet: selectedSheet,
                            selections: selections, // selected columns
                            data: createJsonObject(sheetData, selections),
                            // sidebar: getSidebar(file),
                            // sidebar: {
                            //   class: 'ExcelSidebar',
                            // },
                            id: id
                        })}>Load</Button>
                        :
                        <Button variant="secondary"
                        onClick={() => callback_on_close({
                            file: file,
                            ready: false,
                            selectedSheet: selectedSheet,
                            selections: selections, // selected columns
                            data: createJsonObject(sheetData, selections),
                            sidebar: <h1>Sidebar for {file.name} - closed before ready</h1>,
                            id: id
                        })}>Cancel</Button>
                    }
                    </Col>
                  </Row>
                </Container>
                </Form>


            }
            
        </Modal.Footer>

    </Modal>

}


export default ModalUploadXLSX;