import { Cartesian3, Cartographic, KeyboardEventModifier, Math as CesiumMath, ScreenSpaceEventType } from "cesium";
import { ScreenSpaceEvent, ScreenSpaceEventHandler } from "resium";
import useDataStore from "../helpers/dataStore";
import { useMemo } from "react";
// import search_sa2_by_coordiantes from "./helpers/search_sa2_by_coordinates";

function MapEvents({viewerRef}) {
    const myStore = useDataStore()
      const handle_map_event = (event_type, e) => {
        var viewer = viewerRef.current.cesiumElement
        var ellipsoid = viewer.scene.globe.ellipsoid;
        var c = viewer.camera.pickEllipsoid(new Cartesian3(e.position.x, e.position.y), ellipsoid);
        const cartographic = Cartographic.fromCartesian(c)

        const longitude = CesiumMath.toDegrees(cartographic.longitude)
        const latitude  = CesiumMath.toDegrees(cartographic.latitude)

        console.log(`${event_type} lon ${Math.round(100*longitude)/100}  lat ${Math.round(100*latitude)/100} alt ${cartographic.height}`)
    
        // does the store contain any specific events to be ran for this action?
        const map_events_to_process = (myStore.map_events.filter(e => e.event_type == event_type))

        // console.log(`There are ${map_events_to_process.length} events to process :-)`, map_events_to_process)
        map_events_to_process.map((map_event_config, ix) => {
            map_event_config.callback({
                e: e, 
                longitude: longitude, 
                latitude: latitude, 
                altitude: cartographic.height,
                type: event_type,
            }, myStore)
        })
      }


    return <ScreenSpaceEventHandler>
            <ScreenSpaceEvent action={(e) => { handle_map_event("LEFT_CLICK", e) }} 
                type={ScreenSpaceEventType.LEFT_CLICK} 
            />
            <ScreenSpaceEvent 
                action={(e) => { handle_map_event("RIGHT_CLICK", e) }} 
                type={ScreenSpaceEventType.RIGHT_CLICK}
            />
            <ScreenSpaceEvent
                action={(e) => { handle_map_event("LEFT_DOUBLE_CLICK", e) }}
                type={ScreenSpaceEventType.LEFT_DOUBLE_CLICK}
            />
            <ScreenSpaceEvent
                action={(e) => { handle_map_event("SHIFT+RIGHT_CLICK", e) }}
                type={ScreenSpaceEventType.RIGHT_CLICK}
                modifier={KeyboardEventModifier.SHIFT}
            />
        </ScreenSpaceEventHandler>
}

export default MapEvents